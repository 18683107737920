import { useEffect, useRef, useState } from "react";
import { FromDataForm, fire } from "../../../../util";
import { paging } from "../../../components/MPaging/MPaging";
import { Button, Col, Row } from "react-bootstrap-v5";
import { getAxios } from "../../../components/net";
import { FromFormReference, objectToUrlParams } from "../../../components/MForm/MForm";
import { API_URL } from "../../../components/MConfig";

export const Filtro = props => {
    const formRef = useRef();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [contemFiltro, setContemFiltro] = useState(false);

    const filtrar = e => {
        setContemFiltro(true);
        var res = FromDataForm(formRef.current);
        fire.call(props.chamada, res);
    }
    const objectToUrlParams = (obj) => {
        const params = new URLSearchParams();

        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key])) {
                obj[key].forEach(val => params.append(key, val));
            } else {
                params.append(key, obj[key]);
            }
        });

        return params.toString();
    };

    const downloadFiltro = () => {
        window.showWait(true);
        var obj = FromFormReference(formRef.current);
        var url = API_URL + props.urlDownload + "?" + objectToUrlParams(obj);

        getAxios()({
            url: url,
            method: 'GET',
            responseType: 'blob',
        })
            .then(response => {
                const disposition = response.headers['content-disposition'];
                let filename = 'downloaded_file';
                if (disposition && disposition.includes('filename=')) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urlDownload;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.showWait(false);
            })
            .catch(error => {
                window.showWait(false);
                console.error('Error downloading the file', error);
            });
    };
    const limparFiltro = e => {
        setContemFiltro(false);
        setMostrarFiltros(false);
        formRef?.current?.reset();
        fire.call(props.chamada, paging.request({}));
    }

    const atualizar = () => {
        formRef?.current?.reset();
        fire.call(props.chamada, paging.request({}));
    }

    useEffect(() => {
        if (formRef.current) {
            Array.from(formRef.current).forEach(element => {
                element.addEventListener('keydown', event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                        filtrar();
                    }
                });
            });
        }
    });

    return <>
        <table style={{borderCollapse: "unset"}}>
            <tbody>
            <tr>
                {!props.ocultarNovo &&
                    <td><Button className="mt-5 btn-sm" onClick={() => props.novo()}>
                        <i className="fa fa-file"></i>
                        Novo
                    </Button>
                    </td>
                }
                <td>
                    <Button className="mt-5 btn-sm btn-secondary" onClick={() => setMostrarFiltros(!mostrarFiltros)}>
                        <i className="fa fa-filter"></i>
                        {mostrarFiltros ? "Ocultar " : "Mostrar "} Filtros
                    </Button>
                </td>
                <td>
                    <Button className="mt-5 btn-sm btn-info" onClick={() => atualizar(!mostrarFiltros)}>
                        <i className="fa fa-recycle"></i>
                        Atualizar
                    </Button>
                </td>
                {(mostrarFiltros || contemFiltro) &&
                    <td>
                        <Button className="mt-5 btn-sm btn-warning" alt="Limpar filtros" onClick={() => limparFiltro()} >
                            <i class="fa fa-eraser" aria-hidden="true"></i>
                            Limpar filtro
                        </Button>
                    </td>
                }
            </tr>
            </tbody>
        </table>
        {mostrarFiltros &&
            <>
                <div style={{ zoom: 0.8 }}>
                    <form id="formFiltro" ref={formRef} >
                        {props.children}
                    </form>
                </div>
            </>
        }
        {mostrarFiltros && <>
            <Row>
                <Col className="col-12" style={{ textAlign: "right" }}>
                    <Button className="mt-5 btn-sm" alt="Buscar" onClick={() => filtrar()}>
                        <i class="fa fa-search" aria-hidden="true"></i>
                        Pesquisar
                    </Button>
                    {props.urlDownload &&
                        <Button style={{marginLeft: "10px"}} className="mt-5 btn-sm btn-secondary" alt="Buscar" onClick={() => downloadFiltro()}>
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Download Resultado
                        </Button>
                    }
                </Col>
            </Row>
        </>}


    </>
}