import { React,useEffect, useState } from 'react';
import { MInput } from '../../../components/MInput/MInput';
import { Button } from 'react-bootstrap-v5';
import { API_URL } from '../../../components/MConfig';
import { getAxios } from '../../../components/Mutil';

export const UsuariosPartial = props => {
    const CONSULTA_URL = `${API_URL}/pesquisar/`;
    var [dataUsuarios, setDados] = useState([]);
    const [valorPesquisa, setValorPesquisa] = useState("");
    const [carregando, setCarregando] = useState(false);
    const [lojaAtual, setLojaAtual] = useState(0);
    const [listaLojas, setlistaLojas] = useState([]);

    let timer;

    const buscarLojas = () => {
        const CONSULTA_URL = `${API_URL}/revendedor/ListarRevendedoresPorFiltro`;

        getAxios().get(CONSULTA_URL).then(res => {
            if (Array.isArray(res.data)) {
                var data = res.data.map(x => ({ value: x.id, text: x.name }));
                setlistaLojas(data);
            }
        });
    }

    
    useEffect(() => {
        buscarLojas();
    }, []);


    const getLoja = item => {

        var res = listaLojas.filter(x => item.idPlace == x.value);
        var text = res.length > 0 ? res[0].text : "";
        return text;
    }

    const buscar = (p) => {
        var towait = p == null;
        clearTimeout(timer);
        let v = document.getElementsByName('pesquisa')[0].value;

        var funcPesquisa = function (v = null, p = null) {
            if (valorPesquisa == v && towait) return;
            setValorPesquisa(v);
            var loja = p != null ? p : lojaAtual;
            var data = { termoDaBusca : v, login: v, loja };

            setCarregando(true);

            getAxios().get(API_URL + "/usuario/ListarUsuariosPorFiltro", { params: data }).then(res => {
                setDados(res.data);
                setCarregando(false);
            }).catch(x => {
                setCarregando(false);
            });
        };

        if (towait) {
            timer = setTimeout(() => funcPesquisa(v, lojaAtual), 1000);
        }
        else {
            funcPesquisa(v, p);
        }

    }


    return <>
        <MInput name="pesquisa" title="Pesquisar" placeholder="Digite para pesquisar usuario..."
            onChange={v => buscar()}></MInput>
        {
            carregando ? <div className="spinner-border mt-5 text-primary" role="status"></div> :
                <table className="table table-striped mt-5">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Login</th>
                            <th>Loja</th>
                            <th>Selecionar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataUsuarios.map(x =>
                            (<tr>
                                <td>
                                    {x.nome}
                                </td>
                                <td>
                                    {x.login}
                                </td>
                                <td>
                                    {getLoja(x)}
                                </td>
                                <td>
                                    <Button className="btn-sm" style={{ marginRight: 10 }} onClick={(event) => props.onSelect(x)}>
                                        <i className="fa fa-check"></i></Button>
                                </td>
                            </tr>))
                        }
                    </tbody>
                </table>
        }
    </>
}