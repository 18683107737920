import { React, useState } from 'react';
import { useRef, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap-v5';
import { getAxios } from '../../../components/net';
import { API_URL } from '../../../components/MConfig';

export const Mensagem = props => {

    const [mensagens, setMensagens] = useState([]);
    const [liberado, setLiberado] = useState(false);

    const ref = useRef();
    const mensagem = msgs => {
        setMensagens(msgs);
    };

    const fechar = () => {
        getAxios().post(API_URL + '/Mensagem/MarcarComoLida', { idMensagens: mensagens.map(x => x.Id) });
        setMensagens([]);
    }

    const mostraTipoAlerta = ele => {
        if (ele.TipoAlerta == 0) {
            return <>
                <div class="alert alert-primary" role="alert">
                    <i class="fa fa-info" aria-hidden="true"></i> INFORMATIVO
                </div>
            </>;
        } //Info
        if (ele.TipoAlerta == 1) {
            return <>
                <div class="alert alert-warning" role="alert">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> IMPORTANTE!
                </div>
            </>;
        }  //Alert
        if (ele.TipoAlerta == 2) {
            return <>
                <div class="alert alert-danger" role="alert">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ATENÇÃO
                </div>
            </>;
        }  //Error
    }

    const mudar = e => {
        setLiberado(e.target.checked);
    }

    // Atribuir a referência ao componente
    useEffect(() => {
        ref.current.mensagem = mensagem;
    }, [ref.current]);


    return <div ref={ref} id="msgexibidor" style={{ display: (mensagens.length > 0 ? "block" : "none") }} >
        {mensagens.length > 0 &&
            <>
                <div className='painelMensagem' >
                    <div className='bi fs-1 fa fa-comment-alt icon-xs'></div>
                    <h1>MENSAGEM IMPORTANTE!</h1>
                    <div className='painelItens'>
                        {mensagens.map(ele =>
                        (<>
                            <Row className='painelTitulo'>
                                <Col><h2>{mostraTipoAlerta(ele)}{ele.Titulo}</h2></Col>
                            </Row>
                            <Row className='painelCorpo'>
                                <Col>
                                    {ele.Texto.split('\n').map(x=> <p>{x}</p>)}
                                </Col>
                            </Row>
                        </>
                        ))}
                    </div>
                    <div>
                        <div className='painelRodape'>
                            <input type='checkbox' onChange={(e) => mudar(e)} /> Li e entendi!
                            <Button onClick={() => fechar()} disabled={!liberado} style={{ marginLeft: 20 }}>Fechar</Button>
                        </div>
                    </div>
                </div></>}
    </div>

}    