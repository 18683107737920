export const listaEstados = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
export const tipoEstadoCivil = [
    { value: "Solteiro", text: "Solteiro" },
    { value: "Casado", text: "Casado" },
    { value: "Viuvo", text: "Viúvo" },
    { value: "Separado", text: "Separado judicialmente" },
    { value: "UniaoEstavel", text: "União Estável" },
    { value: "Outros", text: "Outros" }];

export const tipoEmpresas = [
    { id: "Todos", text: "Todos" },
    { id: "IndicadorMaster", text: "Indicador Master" },
    { id: "Indicador", text: "Revenda Indicadora" },
    { id: "Revenda", text: "Revenda"  },
    { id: "Autonomo", text: "Autonomo"  }]