import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import * as auth from './redux/AuthRedux'
import { RootState } from '../../../setup'

export function Logout() {
  const dispatch = useDispatch()
   useEffect(() => {        
    window.localStorage.removeItem("cnf");
    window["cnf"] = undefined;
    
    //document.location.reload()
    dispatch(auth.actions.logout())
    window.localStorage.removeItem('persist:v100-demo1-auth');
    document.location.href = "/auth/login";
  }, [dispatch])
  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
