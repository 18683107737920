export const validar = (function () {
    return {
        preencherComZeros : (numero, tamanho) => {
            let numeroString = numero.toString();
            while (numeroString.length < tamanho) {
                numeroString = '0' + numeroString;
            }
            return numeroString;
        },
        yupValidar : async function(formData,schema){
            try {
                await schema.validate(formData, { abortEarly: false });
                console.log('Form is valid');
                return null;
              } catch (validationErrors) {
                const errors = {};
                validationErrors.inner.forEach((error) => {
                  errors[error.path] = error.message;
                });
                return errors;
              }
        },
        inputCpf: function (input) {
            if(input.target)input = input.target;

            var res = false;
            var cpfLimpo = validar.clearMask(input.value);

            if (cpfLimpo == "") return null;
            
            var c = this.preencherComZeros(cpfLimpo, 11);
            var value = validar.mask(c, "999.999.999-99");
            input.value = value;
            res = validar.cpf(value);

            return res;
        },
        isValidDate : (dateString) => {
            // Verifica se a string da data está no formato correto (YYYY-MM-DD)            
            if (!(!/^\d{2}-\d{2}-\d{4}$/.test(dateString))) {
                return false;
            }
        
            // Extrai os componentes da data
            var parts = dateString.split("-");
            if(parts.length == 1) parts = dateString.split("/");

            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);
        
            // Cria um objeto Date e verifica se é válido
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year &&
                date.getMonth() + 1 === month &&
                date.getDate() === day
            );
        },
        cpf: function (cpf) {
            if (cpf.length == 0) return true;
            cpf = validar.clearMask(cpf);
            if (!cpf || cpf.length != 11
                || cpf == "00000000000"
                || cpf == "11111111111"
                || cpf == "22222222222"
                || cpf == "33333333333"
                || cpf == "44444444444"
                || cpf == "55555555555"
                || cpf == "66666666666"
                || cpf == "77777777777"
                || cpf == "88888888888"
                || cpf == "99999999999")
                return false
            var soma = 0
            var resto
            for (var i = 1; i <= 9; i++)
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
            resto = (soma * 10) % 11
            if ((resto == 10) || (resto == 11)) resto = 0
            if (resto != parseInt(cpf.substring(9, 10))) return false
            soma = 0
            for (var i = 1; i <= 10; i++)
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
            resto = (soma * 10) % 11
            if ((resto == 10) || (resto == 11)) resto = 0
            if (resto != parseInt(cpf.substring(10, 11))) return false
            return true
        },
        email: function (value) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(value);
        },
        mask: function (value, mask) {
            value = this.clearMask(value);
            var tam = value?.length ?? 0
            
            if(tam == 0) return value;

            let indiceTexto = 0;
            let textoFormatado = '';

            for (let i = 0; i < mask.length; i++) {
                if (mask[i] === '9') {
                    textoFormatado += value[indiceTexto] || '';
                    indiceTexto++;
                } else {
                    textoFormatado += mask[i];
                }
            }

            return textoFormatado;
        },
        clearMask: function (value) {          
            var v = value?.replace(/\D/g, '');
            return v;
        },
        cnpj: function (cnpj) {
            if ((cnpj?.length ?? 0) == 0) return true;
            cnpj = this.clearMask(cnpj);
            if (!cnpj || cnpj.length != 14
                || cnpj == "00000000000000"
                || cnpj == "11111111111111"
                || cnpj == "22222222222222"
                || cnpj == "33333333333333"
                || cnpj == "44444444444444"
                || cnpj == "55555555555555"
                || cnpj == "66666666666666"
                || cnpj == "77777777777777"
                || cnpj == "88888888888888"
                || cnpj == "99999999999999")
                return false
            var tamanho = cnpj.length - 2
            var numeros = cnpj.substring(0, tamanho)
            var digitos = cnpj.substring(tamanho)
            var soma = 0
            var pos = tamanho - 7
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--
                if (pos < 2) pos = 9
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
            if (resultado != digitos.charAt(0)) return false;
            tamanho = tamanho + 1
            numeros = cnpj.substring(0, tamanho)
            soma = 0
            pos = tamanho - 7
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--
                if (pos < 2) pos = 9
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
            if (resultado != digitos.charAt(1)) return false
            return true;
        }
    }
}());


