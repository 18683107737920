import { toData, toDateTime } from "../../../../util"
import MPaging from "../../../components/MPaging/MPaging"
import { Button, Col, Row } from "react-bootstrap-v5"
import { useRef } from "react"
import { MModal } from "../../../components/MModal/MModal";
import { getAxios } from "../../../components/net";
import { API_URL } from "../../../components/MConfig";

export const HistoricoProposta = props => {
    const modal = useRef();
     
    function identifyAndDecodeBase64(base64String) {
        try {
            // Decodificar a string base64
            let decodedString = atob(base64String);
            return decodedString;
        } catch (e) {
            console.error("Erro ao decodificar a string base64: ", e);
            return null;
        }
    }

   

    return <>
        <MModal size={1.5} ref={modal} title="Seleção de Anexo" closeText="Fechar" >
            <Col className="col-12">
                <img id="viewImage_Hist" style={{ width: "100%", height: "100%" }} alt="Visualização do Anexo" />
            </Col>
        </MModal>
   <strong>Histórico</strong>
      <div style={{ width: "100%", overflow: "auto" }}>
            <table id="table table-striped" className="table table-striped mt-5">
            <thead>
                <tr>
                    <th>Data/Hora</th>
                    <th>Status</th>
                     <th>Observacao</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    props.dados?.map(x =>
                        <tr className="selecao" key={x.id}>
                            <td>{toDateTime(x.dataCadastro)}</td>
                            <td>{x.statusProposta.nome}</td>                            
                            <td>{x.observacao}</td>
                            <td>{(x.statusProposta.id === 99) &&
                                <Button className="btn-sm" onClick={() => props.visualizarAnexo(x.id,true)}>
                                    <i className='fa fa-eye' > </i>
                                </Button>
                            }</td>
                        </tr>
                    )
                }
            </tbody>
        </table>        
    </div>
 </>
}