import { React, useState } from 'react';
import { useEffect, useRef } from "react";
import { MInput } from "../../../components/MInput/MInput";
import { getAxios } from "../../../components/net";
import { API_URL } from "../../../components/MConfig";
import { MCard, MCardHead } from "../../../components/MCard/MCard";
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap-v5';

export const ConfiguracoesPage = () => {
    
    const [processadora, setProcessadora] = useState(false);
    const [validade, setValidade] = useState(null);
    const [validadeFormatada, setValidadeFormatada] = useState(null);
    
    useEffect(()=> buscar(),[]);

    useEffect(() => {    
        const intervalId = setInterval(() => {          
            
          if (validade != null) {
                
            var valor = new Date(validade) - new Date();
            
            if(valor <= 0) {
                setValidadeFormatada('Vencido!');                
                if(processadora) document.getElementById('habilitado').removeAttribute('checked');
                return;
            }
            
            var diff = Math.abs(valor);
            var minutos = Math.floor(diff / 60000); 
            var segundos = Math.floor((diff % 60000) / 1000); 

            setValidadeFormatada(minutos + ' minutos e ' + segundos + ' segundos');                
        }  

        }, 1000);
    
        return () => clearInterval(intervalId);
      }, validade);
    
 
    const enviarPost = valor => {
        var URL = `${API_URL}/configuracoes`;
        
        getAxios().post(URL, { processadora: valor }).then(res => {
            recarregar(res.data);
            if (document.getElementById('habilitado').checked) {
                Swal.fire("Sucesso!", "A Processadora possui 1hs a contar de agora para utilizar a api de consultas. <br><br> Caso ainda nao o tenho feito,informe este token para sua processadora.", "success");                
            } else {
                Swal.fire("Sucesso!", "Uso desativado!", "success");                
                setValidadeFormatada(null);
            }

            setProcessadora(valor);
 
        }).catch(res => window.modalAlert.Show({ title: "Erro", message: JSON.stringify(res), type: "error" }));
    }

    const buscar = () => {
        window.showWait(true);
        var URL = `${API_URL}/configuracoes`;

        getAxios().get(URL).then(res => {                                
            recarregar(res.data);
            document.getElementById('token').value = res.data.token;                        
            window.showWait(false);
        }).catch(res => window.modalAlert.Show({ title: "Erro", message: JSON.stringify(res), type: "error" }));
    }

    const recarregar = dados =>{
        setValidade(dados.limiteUsoProcessadora);
        setProcessadora(dados.usoProcessadora);
        if (dados.usoProcessadora) 
            document.getElementById('habilitado').checked = 'checked';
        else
            document.getElementById('habilitado').removeAttribute('checked');
    }
 
 
    return <>
        <MCard>
            <MCardHead title={<i className="fa fa-key"> Configurações</i>}></MCardHead>
            <fieldset>
                <legend>Processadora</legend>
                <MInput type="checkbox" title="Habilitar" id="habilitado" onChange={e => enviarPost(e.target.checked)} />

                <Row>
                    <Col><MInput type="textbox" readonly="readonly" id="token" title="Token para processadora" /></Col>
                    <Col>Validade token: {validadeFormatada}</Col>
                </Row>
            </fieldset>
        </MCard>
    </>
};
