import { Alert } from "bootstrap";
import { forwardRef, useImperativeHandle, useState } from "react";

export const MAlert = forwardRef((props, ref) => {
    const [html, setHtml] = useState(null);
    const closeAlert = () => {

        var cnt = 100;
        var m = setInterval(() => {
            var mAlert = document.getElementById('mAlert');
            if(mAlert == null) {
                clearInterval(m);
                return;
            }
            mAlert.style.opacity = cnt / 100;
            cnt -= 10;
            if (cnt <= 0) {
                clearInterval(m);
                setHtml(null);
            }
        }, 250);

    }

    const openAlert = (options = props,closeIt) =>{
        var { type } = { ...options };
        if (type == null) type = "primary";
        if (type == 'error') type = "danger";
        

        setHtml(<div id="mAlert" style={{ position: "fixed", top: 10, right: 30, width: 500, zIndex : 900000 }}
                className={`alert alert-${type} d-flex align-items-center p-5 mb-10`}>
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">...</span>

                <div className="d-flex flex-column">
                    <h5 className="mb-1">{options.title}</h5>
                    <span>{options.message}</span>
                </div>
            </div>);
            closeAlert();
    }
    useImperativeHandle(ref, () => ({
        Pop : openAlert,
        Show : params => {            
            openAlert(params);
        },
        Close: closeAlert
    }));

   

    window.modalAlert = { Show : params => openAlert(params), Close : closeAlert};

    return (html);

});