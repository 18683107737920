import { React, useEffect } from 'react';
import { useState } from "react"

export const WaitModal = () => {
    const [mostrar, setMostrar] = useState(false);
    if (window.isWait == null) window.isWait = false;

    window.showWait = (value) => {        
        if (value == null) value = true;        
        setMostrar(value)
    }

    useEffect(()=>{
                
        var styleContent = "#painelWait{width:100vw;height:100vh;margin:0;background-color:#fc6042;display:flex;justify-content:center;align-items:center;align-content:center}#painelWait>div{width:8vmax;height:8vmax;border-right:4px solid #fff;border-radius:100%;animation:.8s linear infinite spinRight}@keyframes spinLeft{from{transform:rotate(0)}to{transform:rotate(720deg)}}@keyframes spinRight{from{transform:rotate(360deg)}to{transform:rotate(0)}}";        

        var style = document.createElement('style');
        style.innerHTML = styleContent;
        document.head.appendChild(style)

    },[]);

    const configStyle = {
        position: "fixed",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        backgroundColor: "white",
        zIndex: 9999999
    }
 

    useEffect(()=>{
        document.getElementById('splash-screen').style.display =mostrar? "" : "none";
        if(mostrar){
            window.scrollTo({
                top: 0                
            });
        }
    },[mostrar]);

    return null;
    /*
    return mostrar ? <>
        <style id="stcontent"></style>
        <div id="waitPanel" style={configStyle}  >
            <div id="painelWait">
                <div></div>
            </div>
        </div>        
    </> : null;*/
}