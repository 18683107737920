import Swal from "sweetalert2";
import { getAxios } from "../../../components/net";
import { useEffect, useState } from "react";
import { API_URL } from "../../../components/MConfig";
import { MPaging, paging } from "../../../components/MPaging/MPaging";
import { errorAxios, fire, objectToQueryString, toData } from "../../../../util";
import { validar } from "../../services/Validacoes";
import { MInput } from "../../../components/MInput/MInput";

export const ListagemEmpresa = props => {

    const [dados, setDados] = useState({});
    const [filtroAtual, setFiltroAtual] = useState({});
    const [order, setOrder] = useState(true);

    const listarEmpresas = (filtro, pagina = 1) => {
        setFiltroAtual(filtro);
        var paginacao = paging.request(filtro, pagina);
        var URL = `${API_URL}/revendedor/ListarRevendedores?${objectToQueryString(paginacao)}`;
        window.showWait(true);
        getAxios().get(URL).then(res => {
            if (res.data)
                setDados(res.data);
            window.showWait(false);
        }).catch(res => {
            errorAxios(res)
            window.showWait(false);
        }
        );
    }

    const ordem = e =>{          
        var nFiltro = {...filtroAtual};
        var nOrder = order;
        if(nFiltro?.Paginacao?.Ordenacao == e) {
            nOrder = !order;
            setOrder(nOrder);
        }        
        var request = paging.request(nFiltro,1,e,nOrder);        
        listarEmpresas(request, 1);
    }

    const paginar = e => {
        listarEmpresas(filtroAtual, e);
    }

    const normalizaTelefone = e => {
        var tam = validar.clearMask(e.target.value)?.length ?? 0;
        e.target.value = validar.mask(validar.clearMask(e.target.value), (tam == 10 ? "(99)9999-9999" : "(99)99999-9999"));
    }

    useEffect(() => {
        listarEmpresas();
        fire.register('filtrar_empresa', e => {
            listarEmpresas(e);
        });

    }, [])

    return <>
        {dados?.resultado?.length > 0  &&
            <>
                <div style={{width: "100%", overflow: "auto"}}>
                    <table className="table table-striped mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th><a href="#" onClick={e=> ordem('Nome')}>Nome</a></th>
                                <th><a href="#" onClick={e=> ordem('CpfCnpj')}>CNPJ/CPF</a></th>
                                <th><a href="#" onClick={e=> ordem('Responsavel')}>Responsável</a></th>
                                <th><a href="#" onClick={e=> ordem('Contato')}>Contato</a></th>
                                <th><a href="#" onClick={e=> ordem('TipoCadastro')}>Tipo</a></th>                                
                                <th><a href="#" onClick={e=> ordem('SituacaoRevendedor')}>Situação</a></th>
                                <th><a href="#" onClick={e=> ordem('DataCadastro')}>Data Cadastro</a></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dados?.resultado?.map(x => <>
                                    <tr className="selecao" key={x.id} onClick={() => props.editarCadastro(x)}>
                                        <td>{x.id}</td>
                                        <td>{x.nome}</td>
                                        <td>{x.cpfCnpj}</td>
                                        <td>{x.responsavel}</td>
                                        <td>{x.contato && validar.mask(validar.clearMask(x.contato.telefone), (validar.clearMask(x.contato.telefone).length === 10 ? "(99)9999-9999" : "(99)99999-9999"))}</td>                                        
                                        <td>{x?.tipoCadastro}</td>                                        
                                        <td>{x?.situacaoRevendedor?.nome}</td>
                                        <td>{toData(x.dataCadastro)}</td>
                                    </tr>
                                </>)
                            }
                        </tbody>
                    </table>
                    <MPaging data={dados} onGo={paginar} />
                </div>
            </>
        }
    </>

}