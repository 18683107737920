import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { getAxios } from "../Mutil";
export const MForm = forwardRef((props, ref) => {

    var formRef = useRef();
    var [dataset, setData] = useState(null);

    const loadValues = values => {
        formRef.current.reset();
        toFormValues(formRef, values);
        setData(values);
    }
    const reactOptions = ({
        Show(values = null) {
            setTimeout(() => {
                loadValues(values);
            })
        },
        GetForm() {
            return formRef.current;
        },
        GetData() {
            return FromFormReference(formRef.current);
        },
        Load(url, params) {
            if (!url) url = props?.url;
            return new Promise((resolve, reject) => {
                getAxios().get(url + (params ? `?${objectToUrlParams(params)}` : "")).then(res => {
                    loadValues(res.data);
                    resolve(res);
                }).catch(e => reject(e));
            });
        },
        LoadWithParams(params) {
            return this.Load(null,params);
        },
        Save(url, method = "POST") {
            if (!url) url = props?.url;
            return new Promise((resolve, reject) => {
                var dataToSend = FromFormReference(formRef.current);
                dataToSend = props?.onSave({ data: dataToSend, form: formRef.current, prevent: false }) ?? dataToSend;

                if (dataToSend?.prevent ?? false) resolve();

                getAxios()[method.toLowerCase()](url, dataToSend.data).then(res => {
                    resolve(res);
                }).catch(e => {
                    if (reject) reject(e);
                });
            });
        },
        dataset
    });

    useImperativeHandle(ref, () => reactOptions);

    return (

        <form ref={formRef}>
            {props.children}
        </form>
    );
});



export const toFormValues = (formRef, formData) => {
    var items = Array.from(formRef?.current?.elements);
    Array.from(formRef.current.querySelectorAll("[readreact]")).map(x => items.push(x));
    items = items.map(x => ({ name: (x.name ?? x.id).toUpperCase(), item: x }));

    //readReact

    if (formData != null) {

        var mapa = fromObjectToArrayForm(formData).map(x => ({ name: x.name.toUpperCase(), value: x.value }));
        var promises = [];
        mapa.forEach(n => {


            promises.push(new Promise((resolve, reject) => {
                var elementArray = items.filter(x => x.name == n.name)[0];
                if (elementArray != undefined) {

                    var value = n.value;
                    var element = elementArray.item;
                    if (value == undefined || value == null) value = "";
                    if (element != null) {

                        var hasSet = () => {
                            if (element.type == 'checkbox')
                                element.checked = value;
                            if (element.type == 'select' || element.type == "select-one") {
                                selectItemOnInputComponent(element, value);
                            }
                            else {
                                if (element.reactSet) {
                                    element.reactSet(value);
                                }
                                else
                                    element.setAttribute("value", value);
                            }

                        }

                        if (element.onSelect)
                            element.onSelect(value);

                        if (!element.hasSet) {
                            element.hasSet = hasSet;
                            hasSet();
                        } else {
                            element.value = n?.value;
                        }

                    }
                }
            }));
            Promise.all(promises);
        });
    }
}


export const fromObjectToArrayForm = (obj, prefix, lst) => {
    var str = lst ?? [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const k = prefix ? `${prefix}.${key}` : key;
            const v = obj[key];
            if ((v !== null && typeof v === 'object'))
                lst = fromObjectToArrayForm(v, k, str, false);
            else
                str.push({ name: k, value: v });
        }
    }
    return str;
}

//Select item on Select
export const selectItemOnInputComponent = (element, value) => {
    var itemSel = Array.from(element.options).map((z, a) => ({ value: z.value, index: a }))?.filter(q => q.value == value)[0]?.index ?? 0;
    element.selectedIndex = itemSel;
}


//Wait Element and callback function
export const waitForElement = (name, func, tries = 30) => {
    var ntries = 0;
    var ntime = setInterval(() => {
        ntries += 1;
        var el = document.getElementById(name);
        if (el) {
            func(el);
            clearInterval(ntime);
        }
        if (ntries >= tries) clearInterval(ntime);
    });
}

export const FromFormReference = (item, evenDisabled) => {
    var items = Array.from(item);
    var result = {};
    /*
    if(evenDisabled){
        var disabledElements = item.querySelectorAll(':disabled');        
        disabledElements.forEach(function(element) {
            item.append(element.name, element.value);
        });
        items = Array.from(item);
    }
    */


    for (var i in items) {
        var element = items[i];
        if (element.name) {
            var name = element.name.replace('[]', '');

            var dataType = element.attributes['dataType']?.value;

            var value = (dataType == "int" ?
                (isNumeric(element?.value ?? "") ?
                    parseInt(element.value) : null) : element?.value + "");

                    if(value == "" || value == null) continue;

            if (name.indexOf('.') > -1)
                subElement(result, element);

            if (element.name.indexOf('[]') > -1) {
                if (!eval("result." + name))
                    eval("result." + name + " = [];");

                if (element.checked)
                    eval("result." + name + ".push(value)");
                else if (element.type != 'checkbox')
                    eval("result." + name + ".push(value)");
            }
            else {

                if (dataType) {
                    eval("result." + name + " = value");
                } else {
                    eval("result." + name + " = value + ''");
                }

                if (element.checked)
                    eval("result." + name + " = value");
            }
        }
    }

    return result;
}


const subElement = (object, element) => {
    if (element.name.indexOf('.') > -1) {
        var lstName = element.name.split('.');
        var n = [];

        for (var i = 0; i < lstName.length - 1; i++) {
            var xItem = lstName[i];
            n.push(xItem);
            var nEl = n.join(".");
            if (!eval("object." + nEl))
                eval("object." + nEl + " = {}");
        }
    }
}


export function isNumeric(n) {
    n = n?.replace(',', '.') ?? n;

    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function objectToUrlParams(obj) {
    if (typeof obj !== 'object' || obj === null) {
      throw new Error('Input must be an object');
    }
  
    const params = Object.entries(obj)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  
    return params;
  }