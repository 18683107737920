import {Col, Row } from "react-bootstrap-v5"
import { MInput } from "../../../components/MInput/MInput"
import { Filtro } from "../Partial/Filtro";
import { tipoEmpresas } from "../tipos/tiposJs";

export const FiltroEmpresa = props => {

    return <Filtro novo={props.novo} chamada="filtrar_empresa" urlDownload="/revendedor/DownloadRelatorio">
        <Row>
            <Col className="col-md-6 col-12">
                <MInput
                    id="termoDaBusca"
                    type="text"
                    title="Nome ou CPF"
                />
            </Col>
            <Col className="col-md-2">
                <MInput
                    type="date"
                    id="DataCadastroInicio"
                    title="Período Início" />
            </Col>
            <Col className="col-md-2">
                <MInput
                    id="DataCadastroFim"
                    type="date"
                    title="Período Fim" />
            </Col>
            <Col className="col-md-2">
                <MInput
                    id="SituacaoId"
                    type="select"
                    title="Situação"
                    default="Todos"
                    data={props.situacaoEmpresa}
                    onResult={res => res.map(z => ({ value: z.id, text: z.nome }))}
                />
            </Col>
            <Col className="col-md-2">
                <MInput type="select" id="tipoCadastro" data={tipoEmpresas} title="Tipo Cadastro" />
            </Col>
        </Row>
    </Filtro>
}