export const API_URL =
  window.process?.env?.REACT_APP_API_URL ??  
  window.localStorage.getItem('api') ??
  window.apilocal ??
  window.location.href.indexOf("localhost") > -1 ? "https://localhost:44364/api" : window.location.origin + "/api";

 export const APP_SOCKET_URL =    
 window.process?.env?.REACT_APP_SOCKET_URL ??  
 window.localStorage.getItem('socket') ??
 window.socketlocal ??
 window.location.href.indexOf("localhost") > -1 ? 'wss://localhost:44364/ws' : 'wss://' + window.location.hostname + "/ws";

export const axiosHeader = {
  "content-type": "application/json;charset=UTF-8",
  "Accept": "application/json"
};