import { React } from 'react';
import { KTSVG } from "../../../_metronic/helpers";
import { Modal } from 'bootstrap';
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { guid, toFormValues } from "../../../util";
export const MModal = forwardRef((props, ref) => {

    const idModal = props.id ?? guid("_modal_generic");

    var formRef = useRef();
    var modalRef = useRef();
    var btnClose = useRef();
    var [dataset,setData] = useState(null);

    const reactOptions = ({
        Show(values = null) {
            setTimeout(() => {
                var myModal = new Modal(document.getElementById(idModal), {
                    keyboard: false
                });

                
                formRef.current.reset();              
                toFormValues(formRef,values);
                setData(values);
                myModal.show();
            })
        },
        Close() {
            setTimeout(() => {                 
                btnClose?.current?.click();                
            },1000)
        },
        GetForm() {
            return formRef.current;
        },
        GetModal() {
            return modalRef.current;
        },
        dataset    
    });

    useImperativeHandle(ref, () => reactOptions );

    const sizes = () => {
        var size = props.size;
        if (size == 1) return "modal-sm";
        if (size == 2) return "modal-lg";
        if (size == 3) return "modal-xl";
        return "";
    }

    return (
        <div ref={modalRef} className="modal fade" tabIndex={-1} id={idModal} width={(props.width ?? 300) + 'px'} >
            <div className={"modal-dialog " + sizes()}  >
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title ?? "Title"}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <form ref={formRef}>
                            {props.children}
                        </form>
                    </div>
                    <div className="modal-footer">
                        {props.saveButton && <button data-bs-dismiss="modal" type="button" className="btn btn-primary" 
                            onClick={() =>  props?.onClickSave({current : formRef.current, modal :reactOptions})}>
                            {props.saveText ?? "saveText"}
                        </button>}
                        <button ref={btnClose}
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => { props?.onClickCancel && props.onClickCancel({current : formRef.current, modal :reactOptions}) }}
                        >
                            {props.closeText ?? "closeText"}
                        </button>
                    </div>
                </div>
            </div>
        </div>);
});