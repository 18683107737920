export default function setupAxios(axios: any, store: any) {
  //axios.defaults.headers.common['Content-Type'] = 'application/json';  
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()
       
      if (accessToken) {        
        (window as any).token =accessToken.token;
        config.headers.Authorization = `Bearer ${accessToken.token}`
        config.headers.userId = accessToken.id          
        config.headers.placeId = accessToken.idplace
      }

      return config
    },
    (error: any) => {
      
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      Promise.reject(error)
    }
  )
}
