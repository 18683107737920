import { React, forwardRef, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { guid, waitForElement } from "../../../util";
import { Form, InputGroup } from "react-bootstrap-v5";
import { MInputPartialList } from './MInputPartialList';
import { MultiSelect } from 'react-multi-select-component';
import { getAxios } from '../net';
import { MInputPassword } from './MInputPassword';
import * as Yup from 'yup';


export const MInput = forwardRef((props, ref) => {
    const tableRef = useRef();
    const inputRef = useRef();
    const [selected, setSelected] = useState([]);
    const [aguardar, setAguardar] = useState(false);
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const [selectSearchValues, setSelectSearchValues] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    var inputUseState = false;
    var hasSetValue = false;
    let useFirst = true;
    var hasValidate = props.validate != null;
    var internalFilter = function (value) { };

    let timer;


    let newId = props.id ?? props.name ?? props.title;
    if (newId == undefined) newId = props.title ?? "i" + guid("_input_");

    let { params, type, url, name, onSelect, children, onResult, title, useValueOnSearch, ...novaprops } = props;
    if (props.name || newId) novaprops.name = (props.name ?? newId);
    if (newId) novaprops.id = newId.replace('[]', guid("_i_"));
    delete novaprops.data;
    delete novaprops.value;


    if (!novaprops.autoComplete) novaprops.autoComplete = "off";

    //VALIDACOES

    var oldBlur = novaprops.onBlur;

    if (props.required && !hasValidate) {

        var validates = e => {
            var r = e.string().required(`Campo ${props.title} obrigatorio`);

            if (props.type == "email")
                r = r.email('Digite um email válido');

            return r;
        }

        novaprops.validate = validates;

        hasValidate = true;
    }

    var validateCall = async e => {


        var v = e.target.value;

        var obj = novaprops.validate(Yup);

        const validationSchema = Yup.object().shape({ item: obj })

        var res = await validateCampo(validationSchema, 'item', v);


        var warningElement = e.target?.validateElement ?? e.currentTarget?.validateElement ?? e?.validateElement;
        if (res) {
            window.modalAlert.Show({ title: "Erro", message: res, type: "error" });
            if (!warningElement) {
                warningElement = document.createElement('div');
                warningElement.setAttribute('class', 'invalid-feedback');
                warningElement.turnOff = () => {
                    warningElement.style.display = 'none';
                    e.target.classList.remove("is-invalid");
                }
                e.target.validateElement = warningElement;
                e.target.insertAdjacentElement('afterend', warningElement);
            }

            e.target.classList.add("is-invalid");
            warningElement.style.display = 'block';
            warningElement.innerHTML = res;

        } else {
            warningElement && warningElement.turnOff();
        }



        return res;
    }


    if (hasValidate) {
        novaprops.onBlur = async (e) => {
            var tag = e.currentTarget ?? e.target ?? e;
            var ret = { target: tag };
            await validateCall(ret);
            if (oldBlur) oldBlur(ret);
        }


        //var oldOnChange = props.onChange;
        if (props.default && props.type.indexOf("select") > -1) {
            novaprops.onChange = async (e) => {
                var tag = e.currentTarget ?? e.target ?? e;
                var ret = { target: tag };
                await validateCall(ret);
                //if (oldOnChange) oldOnChange(ret);
                if (oldBlur) oldBlur(ret);
            }
        }
    }




    const validateCampo = async (validationSchema, campo, valor) => {
        try {
            await Yup.reach(validationSchema, campo).validate(valor);
            return undefined;
        } catch (error) {
            return error.message;
        }
    };
    //FIM VALIDACOES



    let controle = <input {...novaprops} className="form-control ml-5" />


    useEffect(() => {

        var v = props.data;
        if (v == undefined) v = [];
        if (props.onResult)
            v = props.onResult(v);

        setData(v);
    },
        [props.data]);


    useEffect(() => {

        var input = document.getElementById(newId);
        if (input && input.hasSet) {
            input.hasSet();
        }
    },
        [data]);


    useEffect(() => {
        var input = document.getElementById(newId);
         
        if (inputUseState) {
            input.reactSet = value => {
                var v = value == undefined || value == "null" ? "" : value;

                setSelected(v);

                if (inputRef.current)
                    inputRef.current.value = v;

                input.value = v;
            }
        }

        if (hasSetValue) {

            input.reactSet = value => {
                input.innerHTML = value;
            }
        }

        if (hasValidate && input != null) {
            input.validate = async () => {
                return await validateCall({ currentTarget: input, target: input });
            }
        }

        if (props.onSelect)
            input.onSelect = props.onSelect

        if (input && input.hasSet) {
            input.hasSet();
        }

    }, [])

    if (props.type == 'selectSearch') {
        waitForElement(newId + "_box", e => {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const { width, height } = entry.contentRect;
                    if (tableRef.current) {
                        tableRef.current.style.width = width + "px";;
                        tableRef.current.querySelectorAll("td").forEach(x => {
                            x.setAttribute('style', `width:${width}px`);
                            return x;
                        })
                    }
                }
            });
            resizeObserver.observe(e);

        })
    }
    useEffect(() => {
        if (!props.data && props.url && data?.length == 0) {
            new Promise((resolve, reject) => {
                if (timer != null)
                    clearTimeout(timer);

                timer = setTimeout(() => {
                    if (isFirst && !useFirst) {
                        setIsFirst(false);
                        return;
                    }
                    setAguardar(true);

                    var obj = {};
                    if (props.params) {
                        obj = props.params("");
                    }

                    getAxios().get(url, { params: obj }).then(res => {
                        if (Array.isArray(res.data)) {
                            var v = res.data;
                            if (props.onResult)
                                v = props.onResult(v);
                            //v = internalFilter(v);
                            setData(v);
                        }
                        setAguardar(false);
                    }).catch(x => {
                        setAguardar(false);
                        console.error(x);
                    });
                }, 1000);
            });
        }
    }, [props.url]);

    const handleChange = selectedList => {
        setSelected(selectedList);
    };

    const onSelectItem = item => {
        setSelected(item);
        if (props.onSelect) props.onSelect(item);
    }

    const selectItemSearch = x => {
        props.onSelectItem && props.onSelectItem(x);
        onSelectItem(x.value);
        document.getElementById(newId).value = x.value;
        document.getElementById(newId + "_text").value = x.text;
        setSelectSearchValues([]);
        hasValidate && disableValidate();
    }

    const disableValidate = () => {
        var input = document.getElementById(newId);
        input?.validateElement?.turnOff();
    }

    const buscarSelectSearch = (url, valor, useValueOnSearch) => {
        new Promise((resolve, reject) => {

            if (!url && data) {
                if (valor.length == 0)
                    setSelectSearchValues([]);
                else {
                    var filtered = data.filter(x => (x.text.toLowerCase().indexOf(valor.toLowerCase()) > -1)
                        || (useValueOnSearch ? (x.value + "").toLowerCase().indexOf(valor.toLowerCase()) > -1 : false));
                    filtered = [...filtered];
                    if (useValueOnSearch) filtered = filtered.map(x => ({ text: (x.value + " - " + x.text), value: x.value }));
                    setSelectSearchValues(filtered)
                }

                return;
            } else {

                if (timer != null)
                    clearTimeout(timer);

                timer = setTimeout(() => {
                    setAguardar(true);

                    var obj = { value: valor };

                    if (props.params) {
                        obj = props.params(valor);
                    }

                    getAxios().get(url, { params: obj }).then(res => {
                        if (Array.isArray(res.data)) {
                            var v = res.data;
                            if (props.onResult)
                                v = props.onResult(v);

                            setSelectSearchValues(v);

                            if (v.length == 1) selectItemSearch(v[0]);
                        } else {
                            if (props.onResult) {
                                v = props.onResult(res.data);
                                setSelectSearchValues(v);
                            }
                        }
                        setAguardar(false);
                    }).catch(x => {
                        setAguardar(false);
                        console.error(x);
                    });
                }, 1000);
            }
        });
    }

    const handleOptionClick = option => {

        // You can perform any action you want when an option is clicked
    };


    //GERA INPUTS

    if (props.type == "select") {
        inputUseState = true;
        const handleChange = event => {
            var o = props.onChange;
            var ret = event.target.value;

            if (props.onSelect) props.onSelect(ret);
        };

        const { url, onChange, onSelect, type, ...pp } = novaprops;

        controle = (
            <select className={props.onlyComponent ? "" : 'form-control'} {...pp} onChange={handleChange}  >
                {props.children}
                {
                    props.default && <option key={-1} value="" selected={false} >{props.default}</option>
                }
                {data?.map(x => (<option selected={false} key={x.value} value={x.value ?? x.text} onClick={() => onSelectItem(x)}  >{x.text}</option>))}
            </select>)
    }
    else if (props.type == 'password') {
        controle = <MInputPassword {...novaprops} />
    }
    else if (props.type == 'daterange') {
        /*controle = <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
          />*/
    }
    else if (props.type == 'selectSearch') {
        //novaprops.autoComplete = "off";
        inputUseState = true;
        useFirst = false;
        novaprops.onChange = v => {
            buscarSelectSearch(props.url, v.currentTarget.value, props.useValueOnSearch ?? false);
        }

        controle = <>
            <div id={newId + "_box"} style={{ backgroundColor: "white" }} >
                <MInput {...novaprops} title={props.title} name={(props.name ?? newId) + "_text"} id={(props.name ?? newId) + "_text"} />
                <input type='hidden' dataType={props.dataType} ref={inputRef} name={props.name ?? newId} id={props.name ?? newId} value={selected} />
                {aguardar ? <div className="spinner-border mt-5 text-primary" role="status"></div> :

                    <div style={{ maxHeight: "250px", overflow: "auto", border: "solid 1px dimgray", position: "absolute", backgroundColor: "white" }} >
                        <table className="table table-striped tabela" ref={tableRef} style={{ width: "100%", backgroundColor: "white", zIndex: 99999, display: ((selectSearchValues.length > 0) ? "flex" : "none") }}>
                            <tbody style={{ backgroundColor: "white" }}>
                                {
                                    selectSearchValues.map(x =>
                                    (<tr onClick={() => selectItemSearch(x)} style={{ cursor: "select" }} >
                                        <td>
                                            {x.text}
                                        </td>
                                    </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </>;

        return controle;

    }
    else if (props.type == 'selectCheckbox') {

        internalFilter = function (v) {
            v = v.map(x => ({ value: x.value, label: x.text }));
            return v;
        }

        controle = <>
            {
                selected.map(x => <input type='hidden' name={(newId + "[]")} value={x.value} />)
            }

            <MultiSelect
                placeholder="Selecione..."
                options={data.map(x => ({ value: x.value, label: x.text }))}
                value={selected}
                onChange={handleChange}
                onClick={handleOptionClick}
                labelledBy={props.title ?? ""}
                {...novaprops}
            />
        </>

    }
    else if (props.type == 'list') {
        controle = <MInputPartialList {...novaprops} />
    }
    else if (props.type == "mask") {
        inputUseState = true;
        controle = <InputMask {...novaprops} ref={inputRef} className='form-control' />;
    }
    else if (props.type == "area") {
        controle = <textarea {...novaprops} name={newId} className='form-control' />
    }
    else if (props.type == "date") {
        controle = <Form.Control
            type="date"
            name={newId}
            defaultValue={new Date().toLocaleDateString()}
            placeholder=""
            onChange={(e) => {
                if (props?.onChange) props.onChange(e);
            }} />
    } else {
        if (props.children != null) controle = props.children;
    }

    if (props.type == "checkbox") {
        if (props.onSelect) {
            novaprops.onChange = props.onSelect;
        }
        return (<div className="form-check form-switch form-check-custom form-check-solid me-10">
            <input className="form-check-input h-30px w-50px" type="checkbox" {...novaprops} value={props.value} />
            <label className="form-check-label" for={newId}>
                {props.title}
            </label>
        </div>);
    }

    if (props.type == "label") {
        hasSetValue = true;
        return <span id={newId} name={newId} readreact="true" ></span>
        // readReact= Usado quando exibir dados na tela
    }


    if (props.onlyComponent) return controle;

    return <>
        <div className="mt-5">
            {props.title ? <label className={"form-label" + (hasValidate ? " required" : "")}>{props.title}</label> : null}
            {
                props.width != null ? <div style={{ width: props.width }}>{controle}</div> : controle
            }
        </div>
    </>
});