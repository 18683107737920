import { useEffect, useState } from "react"
import { MCard, MCardHead } from "../../../components/MCard/MCard"
import { fire, getPermissao } from "../../../../util"
import { ListagemEmpresa } from "./ListagemEmpresa"
import { CadastroEmpresa } from "./CadastroEmpresa"
import { FiltroEmpresa } from "./FiltroEmpresa"
import { getAxios } from "../../../components/net"
import { API_URL } from "../../../components/MConfig"
import { tipoPerfil, tipoPermissao } from "../tipos/tipos"

export const Empresa = props => {

    const [edit, setEdit] = useState(false);
    const [toFire, setFire] = useState({});
    const [lista, setLista] = useState([]);
    const [situacaoEmpresa, setSituacaoEmpresa] = useState([]);


    const novo = () => {
        setEdit(true);
        setFire({ event: 'nova_empresa' });
    }

    const irPaginaPrincipal = () => {
        setEdit(false);
    }

    const editarCadastro = e => {
        setEdit(true);
        setFire({ event: 'editar_empresa', e });
    }

    useEffect(() => {

        getAxios().get(API_URL + "/revendedor/ListarSituacoesRevendedor").then(res => {
            if (res.data) {
                setSituacaoEmpresa(res.data);
            }
        })

    }, [])

    useEffect(() => {
        if (toFire.event == undefined) return;
        fire.call(toFire.event, toFire.e);
    }, [edit]);


    return <>
        {getPermissao(tipoPermissao.criar_revendedor, tipoPerfil.Backoffice) &&
            <MCard>
                <MCardHead title={<i className="fa fa-home"> Cadastro de Empresas</i>}></MCardHead>
                <div style={{ display: (!edit ? "block" : "none") }}>
                    <FiltroEmpresa situacaoEmpresa={situacaoEmpresa} novo={novo} />
                    <ListagemEmpresa editarCadastro={editarCadastro} lista={lista} />
                </div>
                {edit && //Novo e Edicao
                    <>
                        <CadastroEmpresa situacaoEmpresa={situacaoEmpresa} irPaginaPrincipal={irPaginaPrincipal} />
                    </>
                }

            </MCard>}
    </>
}