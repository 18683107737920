/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Col } from 'react-bootstrap-v5'

export function AuthPage() {
  useEffect(() => {
    document.getElementById('fundao')?.setAttribute('style', "backgroundImage=url('" + toAbsoluteUrl('/media/logos/fundao.png') + "');" );
  }, [])

  return (
    <>
      <div id="fundao">

      </div>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-right bgi-no-repeat bgi-size-contain bgi-attachment-fixed' >

        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            {/*<img alt='Logo' style={{ zoom: 0.7 }} src={toAbsoluteUrl('/media/logos/logo.png')} className='h-85px' /> */}
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Switch>
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/registration' component={Registration} />
              <Route path='/auth/forgot-password' component={ForgotPassword} />
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}

      </div>
    </>

  )
}
