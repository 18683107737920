import { React,useState } from 'react';
import { getAxios } from '../../../components/net';
import { MInput } from '../../../components/MInput/MInput';
import { API_URL } from '../../../components/MConfig';
import { Button } from 'react-bootstrap-v5';

export const LojasPartial = props => {

    const editar = item => {}

    const [lojaAtual, setLojaAtual] = useState({});
    const [aguardar, setAguardar] = useState(false);
    const [listaLojas, setlistaLojas] = useState([]);
    
    let timer;

    const buscarLojas = valor => {

        clearTimeout(timer);
        timer = setTimeout(() => {
            const CONSULTA_URL = `${API_URL}/revendedor/ListarRevendedoresPorFiltro`;
            setAguardar(true);
            getAxios().get(CONSULTA_URL, { params: { termoDaBusca: valor } }).then(res => {
                if (Array.isArray(res.data)) {
                    setlistaLojas(res.data);
                }
                setAguardar(false);
            }).catch(x => setAguardar(false));
        }, 1000);
    }

    return <>
    <MInput title="Pesquisar" placeholder="Digite para pesquisar loja..." onChange={v => buscarLojas(v.currentTarget.value)}></MInput>
    {
        aguardar ? <div class="spinner-border mt-5 text-primary" role="status"></div> :

            listaLojas.length > 0 &&
            <table className="table table-striped mt-5">
                <thead>
                    <tr>
                        <th>Loja</th>
                        <th>Selecionar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listaLojas.map(x =>
                        (<tr key={x.id}>
                            <td>
                                {x.nome}
                            </td>                           
                            <td>
                                <Button className="btn-sm" style={{ marginRight: 10 }} onClick={(event) => props.onSelect(x)}>
                                    <i className="fa fa-check"></i></Button>
                            </td>
                        </tr>))
                    }
                </tbody>
            </table>
    }</>
}