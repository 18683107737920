import { React, useRef, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap-v5';
import { getAxios } from '../../../components/net';
import { API_URL } from '../../../components/MConfig';
import { MCard, MCardHead } from '../../../components/MCard/MCard';
import { FromDataForm, dateFormat, getPermissao } from '../../../../util';
import { MModal } from '../../../components/MModal/MModal';
import { MInput } from '../../../components/MInput/MInput';
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import { LojasPartial } from '../Partial/LojasPartial';
import Swal from 'sweetalert2';
import { UsuariosPartial } from '../Partial/UsuariosPartial';
import { setuid } from 'process';
import { FromFormReference } from '../../../components/MForm/MForm';
import { tipoPerfil } from '../tipos/tipos';
export const MensagensPage = props => {

    const [mensagens, setMensagens] = useState([]);
    const [mensagemAtual, setMensagemAtual] = useState({});
    const [usuarioAtual, setUsuarioAtual] = useState(null);    
    const [tipoAtual, setTipo] = useState(0);
    const [codigo, setCodigo] = useState(0);
    const [lojaAtual, setLoja] = useState(0);
    const [tipoCadastro, setTipoCadastro] = useState(0);
    const [descricao, setDescricao] = useState("");
    const [tipoExibicao, setTipoExibicao] = useState(0);

    const modalExcluir = useRef();
    const modalCadastro = useRef();


    const selecaoLoja = e => {        
        setLoja(e);
        setCodigo(e.id);
        setDescricao(e.name);
    }
    const selecaoUsuario = e =>{                
        setUsuarioAtual(e);
        setCodigo(e.id);
        setDescricao(e.username);
    }
    const editar = item => {        
        setTipoCadastro(1);
        getAxios().get(API_URL + "/mensagem/" + item.id).then(msg => {
            if (msg.data)
            var item = msg.data;
            setTipo(item.tipo);
            setDescricao(item.descricao);
            setMensagemAtual(item);

            if(item.tipo == 1) setLoja({id:item.codigo, name: item.descricao });
            if(item.tipo == 2) setUsuarioAtual({id:item.codigo, login: item.descricao });

            modalCadastro.current.Show(item);
        });        
    }
    const novo = () => {
        setTipoCadastro(0);
        setLoja(null);     
        setUsuarioAtual(null);
        setCodigo(0);
        setMensagemAtual({id:0});
        setDescricao("");

        modalCadastro.current.Show();
    }

    const excluir = item => {
        setMensagemAtual(item);
        modalExcluir.current.Show();
    }
    const carregar = () => {
        getAxios().get(API_URL + "/mensagem/getall").then(msg => {
            if (msg.data)
                setMensagens(msg.data);
        });
    }
    useEffect(() => {
        carregar();
    }, []);

    const confirmarExclusao = () => {

        const CONSULTA_URL = `${API_URL}/mensagem`;

        getAxios().delete(CONSULTA_URL + '/' + mensagemAtual.id).then(res => {
            window.modalAlert.Show({ title: "Sucesso!", message: "Excluido  com sucesso!", type: "info" })
            carregar();
        }).catch(x => {
            window.modalAlert.Show({ title: "Erro", message: JSON.stringify(x), type: "error" })
        });
    }

    const alterarLoja = () => {
        setLoja(null);
        setCodigo(0);
    }
    const alterarUsuario = () => {
        setUsuarioAtual(null);
        setCodigo(0);
    }


    const confirmarSalvar = () => {
        
        if (tipoAtual == 1 && codigo == 0) {
            window.modalAlert.Show({ title: "Sem loja!", message: "Selecione uma loja!", type: "info" });
            return false;
        }
        if (tipoAtual == 2 && codigo == 0) {
            window.modalAlert.Show({ title: "Sem usuario!", message: "Selecione um usuario!", type: "info" });
            return false;
        }
        var dataPost =  FromFormReference(modalCadastro.current.GetForm());
        if(tipoCadastro == 1) dataPost.id = mensagemAtual.id;
        dataPost.codigo = codigo;

        if(dataPost.texto == null || dataPost.titulo == null){
            window.modalAlert.Show({ title: "Informe!", message: "Titulo e texto devem ser preenchidos!", type: "info" });
            return false;
        }

        var URL = `${API_URL}/mensagem`;
        dataPost.id = parseInt(dataPost.id);
        console.log({data: dataPost});

        if (tipoCadastro == 0) {
            getAxios().post(URL, dataPost).then(res => {
                window.modalAlert.Show({ title: "Sucesso!", message: "Cadastro com sucesso!", type: "info" })
                carregar();
            }).catch(res=> window.modalAlert.Show({ title: "Erro", message: JSON.stringify(res), type: "error" }));
        } else {
            getAxios().put(URL, dataPost).then(res => {
                window.modalAlert.Show({ title: "Sucesso!", message: "Atualizado com sucesso!", type: "info" })
                carregar();
            }).catch(res=> window.modalAlert.Show({ title: "Erro", message: JSON.stringify(res), type: "error" }));
        }
    }

    const mudar = e =>{
        setTipoExibicao(e ? 1 : 0);
    }

    if(!getPermissao(null,tipoPerfil.AdminMaster))return null;

    return <>
        <MModal id="modalCadastro" ref={modalCadastro}
            title="Cadastro de Mensagem"
            closeText="Cancelar" saveButton={true}
            saveText="Salvar"  
            size={2}
            onClickSave={res => confirmarSalvar()}  >
                <input type='hidden' name="id" value={mensagemAtual.id} />
                <input type='hidden' name ="descricao" value={descricao} />
            <table className='table'  >
                <tr>
                    <td>
                        <MInput type="select" title="Tipo:" dataType="int" id="tipo" onSelect={e => setTipo(e)}  >
                            <option value={0}>Geral</option>
                            <option value={1}>Empresa Especifica</option>
                            <option value={2}>Usuario Especifico</option>
                            <option value={3}>Administradores</option>
                        </MInput>
                    </td>
                    <td>
                        <MInput type="select" dataType="int" title="Tipo de Alerta:" id="tipoAlerta"  >
                            <option value={0}>Informacao</option>
                            <option value={1}>Importante</option>
                            <option value={2}>Atenção</option>
                        </MInput>
                    </td>
                    <td>
                        <MInput type="date" title="Data Limite:" id="dataLimite" />
                    </td>
                </tr> 
                {(tipoAtual == 1) && <>
                    <tr>
                        <td>
                            <label class="form-label">Tipo</label>
                        </td>
                    </tr>
                    {(lojaAtual &&
                        <tr>
                            <td>Empresa Atual: {lojaAtual?.nome} </td>
                            <td><Button onClick={() => alterarLoja()}>Alterar</Button> </td>
                        </tr>)
                    }
                    {!lojaAtual &&
                        <tr><td colSpan={3}>
                            <LojasPartial onSelect={e => selecaoLoja(e)} />
                        </td></tr>
                    }
                </>
                }
                {(tipoAtual == 2) && <>
                    <tr>
                        <td>
                            <label class="form-label">Usuario</label>
                        </td>
                    </tr>
                    {usuarioAtual &&
                        <tr>
                            <td>Usuario Atual: {usuarioAtual?.login} </td>
                            <td><Button onClick={() => alterarUsuario()}>Alterar</Button> </td>
                        </tr>
                    }
                    {!usuarioAtual &&
                        <tr><td colSpan={3}>
                            <UsuariosPartial onSelect={e => selecaoUsuario(e)} />
                        </td></tr>
                    }
                </>
                }
                <tr>
                    <td colSpan={3}>
                        <MInput type="text" title="Titulo:" id="titulo" />
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <MInput type="area" title="Texto:" id="texto" />
                    </td>
                </tr>
            </table>                            
        </MModal>
        <MModal id="modalExcluir" ref={modalExcluir}
            title="Excluir usuário?"
            closeText="Não" saveButton={true}
            saveText="Confirmar"
            onClickSave={res => confirmarExclusao()}  >
            Deseja realmente excluir a mensagem?
            <table className="table mt-5">

                <tr >
                    <td>Titulo:</td>
                    <td>{mensagemAtual.titulo}</td>
                </tr>
                <tr>
                    <td>Destino:</td>
                    <td>{mensagemAtual.destino} {mensagemAtual.descricao}</td>
                </tr>
            </table>
        </MModal>
        <MCard>
            <MCardHead title={<i className="fa fa-comment-alt"> Cadastro de Mensagens</i>}></MCardHead>
            <Row>
                <Col><button   className="btn btn-primary" onClick={() => novo()}>Adicionar</button>                        </Col>
                <Col><MInput style={{marginTop:10}} onChange={e=> mudar(e.target.checked)} type='checkbox' id='opcao'  title='Mensagens Automaticas' /> </Col>
            </Row>
            
            
            <table className="table table-striped mt-5">
                <thead>
                    <tr>
                        <th>Titulo</th>
                        <th>Destino</th>
                        <th>Data Cadastro</th>
                        <th>Data Limite</th>
                        <th></th>
                    </tr>
                </thead>
                {mensagens.filter(x=> x.automatica == tipoExibicao).map(x =>
                    <tr>
                        <td>{x.titulo}</td>
                        <td>{x.destino} {x.descricao}</td>
                        <td>{dateFormat(x.dataCadastro)}</td>
                        <td>{x.dataLimite}</td>
                        <td>
                            <Button className="btn-sm" style={{ marginRight: 10 }} onClick={(event) => editar(x)}>
                                <i className="fa fa-edit"></i></Button>

                            <Button className="btn-danger btn-sm" onClick={(event) => excluir(x)}>
                                <i className="fa fa-trash"></i></Button>
                        </td>
                    </tr>)}
            </table>
        </MCard>
    </>
}