import {  Col } from "react-bootstrap-v5"
import { MInput } from "../../../components/MInput/MInput"
import { API_URL } from "../../../components/MConfig";
import { getPermissao,  } from "../../../../util";
import { Filtro } from "../Partial/Filtro";
import { tipoPermissao,tipoPerfil } from "../tipos/tipos";

export const FiltroUsuario = props => {

    return <Filtro novo={props.novo} ocultarNovo={!getPermissao(tipoPermissao.criar_usuario, tipoPerfil.Backoffice)} chamada="filtrar_empresa"  urlDownload="/usuario/DownloadRelatorio">
        <Col className="col-md-4 col-12">
            <MInput
                id="termoDaBusca"
                type="text"
                title="Nome,cpf ou login"
            />
        </Col>
        {getPermissao(tipoPermissao.criar_usuario, tipoPerfil.Backoffice) &&
            <Col className="col-md-4 col-12">
                <MInput id="RevendedorId" type="selectSearch"
                    title="Empresa"
                    url={API_URL + "/revendedor/ListarRevendedores"}
                    params={v => ({ TermoDaBusca: v })}
                    onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                    placeholder="Digite o nome da empresa..." />
            </Col>}
        <Col className="col-md-2 col-8">
            <MInput 
                id="SituacaoUsuarioId"
                type="select"
                title="Situação"
                data={props.situacaoUsuario}
                default="Todos"
                onResult={res => res.map(z => ({ value: z.id, text: z.nome }))}
            />
        </Col>
    </Filtro>
}