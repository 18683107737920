import axios from 'axios';
export function isNumeric(n) {
    n = n?.replace(',', '.') ?? n;

    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getAxios = ()=>{
    
    axios.defaults.headers = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',         
    };

    if(window.token != null && window.token != undefined) 
        axios.defaults.headers.Authorization = {token : `Bearer ${window.token}`};

    return axios;
}