import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MInput } from "../MInput/MInput";

export const MPaging = forwardRef((props, ref) => {
    const [filtroAtual, setFiltroInterno] = useState({});
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState({});
    const [runCall, setRuncall] = useState({});

    const setInternal = (e) => {
        setFiltroAtual(paging.response(e));
    }

    const setFiltroAtual = (e, runGo = true) => {
        setFiltroInterno(e);
        if (runGo) setRuncall(!runCall);
    }

    const goToPage = (currentPage = 1, orderColumn) => {
        var preparedFilter = paging.request(filtroAtual, currentPage, orderColumn);
        setFiltroAtual(preparedFilter);
    }

    useImperativeHandle(ref, () => ({
        setFilter: filter => {
            var result = paging.request(filter);
            setFiltroAtual(result);
        },
        set: value => {
            setInternal(value);
        },
        goToPage: (currentPage, orderColumn) => {
            goToPage(currentPage, orderColumn);
        },
        setOrder: (order, page) => {
            var result = paging.order(order, page, filtroAtual);
            setFiltroAtual(result, false);
            return result;
        },
        current, data
    }));

    useEffect(() => {
        var pos = filtroAtual?.Paginacao?.PaginaDesejada ?? 1;
        props?.onGo(pos);
    }, [runCall])

    useEffect(() => {
        setData(props.data);
        setCurrent(paging.parseResponse(props.data));
    }, [props.data])

    const handlePageChange = e => {
        props?.onGo(e);
    }

    const changeMax = e => {
        paging.defaultQuantity = e;
        setFiltroAtual(paging.request(filtroAtual));
    }

    const renderPageNumbers = (currentBlock) => {
        if (currentBlock == null || currentBlock.length == 0 || !(current?.pages)) return;

        const pageNumbers = [];

        for (let i = 1; i <= current?.pages; i++) {


            if (i > currentBlock[0]?.min && i <= currentBlock[0]?.max) {
                pageNumbers.push(
                    <li class={`paginate_button page-item ${current.current == i ? "active" : ""}`} onClick={() => handlePageChange(i)}  >
                        <a href="#" aria-controls="m_table_1" data-dt-idx={i} tabindex="0" class="page-link">{i}</a>
                    </li>
                );
            }
        }
        return pageNumbers;
    };

    if (!(current?.current)) return null;

    let dataBlocks = [];
    let dataQtd = [];
    let currentBlock = null;
    const maxPageToshow = 10;
    if (current?.current) {

        var blocks = Math.round((current?.pages ?? 0) / maxPageToshow) + 1;
        for (let i = 1; i <= blocks; i++) {
            dataBlocks.push({ text: i, value: i });
        }

        currentBlock = dataBlocks.map(x => ({ current: x.value, min: ((x.value - 1) * maxPageToshow), max: (x.value * maxPageToshow) })).filter(x => current.current > x.min && current.current <= x.max);

        dataQtd.push(paging.defaultMinQuantity);
        dataQtd.push(20);
        dataQtd.push(30);
        dataQtd.push(50);
        dataQtd.push(100);
    }

    if (currentBlock == null || !current) return null;

    return (
        <>
            <nav>
                <ul className="pagination">
                    <li class={`paginate_button first page-item ${(current.current == 1 ? 'disabled' : '')}`} id="m_table_1_first" title="Inicio"
                    >
                        <a href="#" aria-controls="m_table_1" data-dt-idx="0" tabindex="0" class="page-link" onClick={() => handlePageChange(1)} >
                            <i class="la la-angle-double-left"></i>
                        </a>
                    </li>
                    <li class={`paginate_button previous page-item ${(current.current == 1 ? 'disabled' : '')}`} id="m_table_1_previous" title="Anterior"
                    >
                        <a href="#" aria-controls="m_table_1" data-dt-idx={current.current - 1} tabindex="0" class="page-link" onClick={() => handlePageChange(current.current - 1)} >
                            <i class="la la-angle-left"></i>
                        </a>
                    </li>
                    {renderPageNumbers(currentBlock)}
                    <li class={`paginate_button previous page-item ${(current.current == current.pages ? 'disabled' : '')}`} id="m_table_1_next" title="Próximo" >
                        <a href="#" aria-controls="m_table_1" data-dt-idx={current.current - 1} tabindex="0" class="page-link" onClick={() => handlePageChange(current.current + 1)} >
                            <i class="la la-angle-right"></i>
                        </a>
                    </li>
                    <li class={`paginate_button previous page-item ${(current.current == current.pages ? 'disabled' : '')}`} id="m_table_1_last" title="Fim">
                        <a href="#" aria-controls="m_table_1" data-dt-idx="8" tabindex="0" class="page-link" onClick={() => handlePageChange(current.pages)} >
                            <i class="la la-angle-double-right"></i>
                        </a>
                    </li>
                    <li>
                        <table style={{marginTop: 5}}>
                            <tr>
                                <td><MInput id="pgblock"  onSelect={e => changeMax(e)} onlyComponent type="select" data={dataQtd} onResult={x => x.map(z => ({ text: z, value: z }))} /></td>
                                <td>Listando</td>
                                {console.log(data)}
                                <td>{data.qtdResultado <= paging.defaultQuantity ? data.qtdResultado :  paging.defaultQuantity} de {data?.qtdTotal} registros
                                </td>
                            </tr>
                        </table>
                    </li>
                    {/*
                    <li>
                        <MInput onlyComponent type="select" data={dataBlocks} />
                    </li>
                    <li>
                        Bloco {currentBlock[0]?.current} de {dataBlocks.length}
                    </li>
                     */}
                </ul>
            </nav> 
        </>
    );
});

export const paging = {
    defaultMinQuantity: 8,
    defaultQuantity: 8,
    parse: item => {
        if (Array.isArray(item)) {
            item = { data: item, }
        }
    },
    request: (filter, page, column, orderDescend = null) => {
        filter = !filter ? {} : { ...filter };
        filter.Paginacao = !filter.Paginacao ? {} : filter.Paginacao;
        filter.Paginacao.PaginaDesejada = page ?? filter.Paginacao.PaginaDesejada ?? 1;
        filter.Paginacao.ResultadosPorPagina = paging.defaultQuantity;
        filter.Paginacao.Ordenacao = column ?? filter?.Paginacao?.Ordenacao ?? "Id";
        filter.Paginacao.OrdemDescendente = orderDescend ?? filter?.Paginacao?.OrdemDescendente ?? true;
        return filter;
    },
    response: (responseObject) => {
        return {
            current: responseObject.paginaAtual,
            pages: responseObject.qtdPaginas,
            data: responseObject.resultado
        };
    },
    order: (order, page, currentFilter) => {
        currentFilter = paging.request(currentFilter, null);
        var nFiltro = paging.request(currentFilter, page, order);

        if (order && nFiltro.Paginacao.Ordenacao == currentFilter.Paginacao.Ordenacao)
            nFiltro.Paginacao.OrdemDescendente = !nFiltro?.Paginacao.OrdemDescendente;

        return nFiltro;
    },
    parseResponse: requestData => {

        return {
            pages: requestData.qtdPaginas,
            current: requestData.paginaAtual
        }
    }
};
export default MPaging;