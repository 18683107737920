import Swal from "sweetalert2";
import { getAxios } from "../../../components/net";
import { useEffect, useState } from "react";
import { API_URL } from "../../../components/MConfig";
import { MPaging, paging } from "../../../components/MPaging/MPaging";
import { errorAxios, fire, objectToQueryString, toData, getPermissao } from "../../../../util";
import { Button } from "react-bootstrap-v5";
import { tipoPermissao, tipoPerfil } from "../tipos/tipos";

export const ListagemUsuario = props => {
    const [dados, setDados] = useState({});
    const [filtroAtual, setFiltroAtual] = useState({});
    const [order, setOrder] = useState(true);

    const listarUsuarios = (filtro, pagina = 1) => {
        setFiltroAtual(filtro);
        window.showWait(true);
        var paginacao = paging.request(filtro, pagina, filtro?.Paginacao?.Ordenacao ?? "id");
        var URL = `${API_URL}/usuario/ListarUsuarios?${objectToQueryString(paginacao)}`;
        getAxios().get(URL).then(res => {
            if (res.data) setDados(res.data);
            window.showWait(false);
        }).catch(res => {
            errorAxios(res);
            window.showWait(false);
        });
    };

    const ordem = e => {
        var nFiltro = { ...filtroAtual };
        var nOrder = order;
        if (nFiltro?.Paginacao?.Ordenacao === e) {
            nOrder = !order;
            setOrder(nOrder);
        }
        var request = paging.request(nFiltro, 1, e, nOrder);
        listarUsuarios(request, 1);
    };

    const paginar = e => {
        listarUsuarios(filtroAtual, e);
    };

    useEffect(() => {
        listarUsuarios();
        fire.register('filtrar_empresa', e => {
            listarUsuarios(e);
        });
    }, []);

    const visualizar = (e, x) => {
        e.stopPropagation();
        props.visualizarUsuario(x);
    };

    return <>
        {dados?.resultado?.length > 0 &&
            <>
                <div style={{ width: "100%", overflow: "auto" }}>
                    <table className="table table-striped mt-5 tabela">
                        <thead>
                            <tr>
                                <th><a href="#" onClick={e => ordem('Nome')}>Nome</a></th>
                                <th><a href="#" onClick={e => ordem('Cpf')}>Cpf</a></th>
                                <th><a href="#" onClick={e => ordem('Login')}>Login</a></th>
                                <th><a href="#" onClick={e => ordem('revendedorId')}>Empresa</a></th>
                                <th><a href="#" onClick={e => ordem('SituacaoUsuarioId')}>Situação</a></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dados?.resultado?.map(x => (
                                <tr className="selecao" key={x.id} onClick={e => visualizar(e, x)}>
                                    <td>{x.nome}</td>
                                    <td>{x.cpf}</td>
                                    <td>{x.login}</td>
                                    <td>{x.revendedorNome}</td>
                                    <td>{x?.situacaoUsuarioNome}</td>
                                    <td style={{ zoom: 0.6 }} className="no-click">
                                        {getPermissao(tipoPermissao.criar_usuario, tipoPerfil.Backoffice) &&  <Button title="Editar" style={{ marginRight: 5 }} onClick={(e) => { e.stopPropagation(); props.editarCadastro(x); }}>
                                            <i className="fa fa-edit" aria-hidden="true"></i>
                                        </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <MPaging data={dados} onGo={paginar} />
                </div>
            </>
        }
    </>;
};
