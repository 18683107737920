import { React, useState } from 'react';
import { Button, Form } from "react-bootstrap-v5";
import { getAxios } from '../net';
import { MInput } from './MInput';

export const MInputPartialList = (props) => {

    const [lista, setLista] = useState([]);
    const [aguardar, setAguardar] = useState(false);

    let timer;

    const buscar = valor => {

        clearTimeout(timer);
        timer = setTimeout(() => {
            const CONSULTA_URL = props.url;
            setAguardar(true);
            getAxios().get(CONSULTA_URL, { params: { name: valor } }).then(res => {
                if (Array.isArray(res.data)) {
                    setLista(res.data);
                }
                setAguardar(false);
            }).catch(x => setAguardar(false));
        }, 1000);
    }

    if(props.data){
        setLista(props.data);
    }
 

    return <>
        <MInput type="text" {...props} onChange={v => buscar(v.currentTarget.value)}></MInput>
        {
            aguardar ? <div className="spinner-border mt-5 text-primary" role="status"></div> :

                lista.length > 0 &&
                <table className="table table-striped mt-5">
                    <thead>
                        <tr>
                            <th>Loja</th>
                            <th>Selecionar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lista.map(x =>
                            (<tr>
                                <td>
                                    {x.name}
                                </td>
                                <td>
                                    <Button className="btn-sm" style={{ marginRight: 10 }} onClick={(event) => props.onSelect(x)}>
                                        <i className="fa fa-check"></i></Button>
                                </td>
                            </tr>))
                        }
                    </tbody>
                </table>
        }
    </>

}