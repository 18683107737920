import React, { useEffect, useRef, useState } from 'react';
import { ValidateForm, fire, generateStrongPassword, getPermissao, getRole, modalAlert, password_generateStrong, password_isStrongPassword, selectItemOnInputComponent, setStringValues, toFormValues, waitForElement, getConfig } from '../../../../util';
import { MInput } from '../../../components/MInput/MInput';
import { Button, Col, Dropdown, Row } from 'react-bootstrap-v5';
import { validar } from '../../services/Validacoes';
import { API_URL } from '../../../components/MConfig';
import { getAxios } from '../../../components/net';
import { tipoPermissao, tipoPerfil } from "../tipos/tipos";
import Swal from 'sweetalert2';
import { tipoEstadoCivil } from '../tipos/tiposJs';
import { FromFormReference } from '../../../components/MForm/MForm';

export const CadastroUsuario = props => {
    var formRef = useRef();
    const [id, setId] = useState(0);
    const [permissoes, setPermissoes] = useState([]);
    const [senhaAtual, setSenhaAtual] = useState("");
    const [propSenha, setPropSenha] = useState();
    const tipoSexo = [{ value: "Masculino", text: "Masculino" }, { value: "Feminino", text: "Feminino" }, { value: "Outro", text: "Outro" }];
    const [propPerfilId, setPerfilId] = useState(0);

    const cancelar = e => {
        props.irPaginaPrincipal();
    };

    const normalizaTelefone = e => {
        var tam = validar.clearMask(e.target.value)?.length ?? 0;
        e.target.value = validar.mask(e.target.value, (tam == 10 ? "(99)9999-9999" : "(99)99999-9999"));
    };

    const selecaoPerfil = e => {
        getAxios().get(API_URL + "/Usuario/ListarPermissoesPorPerfilId/" + e).then(res => {
            setPermissoes(res.data);
        });
    };
 
    const setSenha = (v) => {
        setSenhaAtual(v);

        if (v.length > 0 || id === 0) {
            var required = true;
            setPropSenha({ required });
        } else {
            setPropSenha(null);
        }
    };

    const validarCpfCnpj = e => {
        var res = validar.cpf(e);
        if (!res) {
            window.modalAlert.Show({ title: "Atencao!", message: "Cpf inválido!", type: "error" });
            return false;
        }
        return true;
    };

    const validarSenha = () => {
        var senha = document.getElementById('Senha').value;
        var senhaConfirmacao = document.getElementById('SenhaConfirmacao').value;

        if (id == 0 && senha == "") return false;

        if (senha != senhaConfirmacao && senha != "") {
            Swal.fire("Senha diferente!", "Senha difere da confirmação!");
            return false;
        }

        if (!password_isStrongPassword(senha, 6) && senha != "") {
            Swal.fire("Senha fraca!", `Senha fraca!<br>
            <br>Coloque: 
            <br>
            <ul>
                <li>Letra maiuscula</li>
                <li>Letra minuscula</li>
                <li>Caracter especial</li>
                <li>Numero(s)</li>
                <li>No minimo 6 digitos</li>
            </ul><br><br>Você pode também gerar uma senha automática, clicando no botão logo acima do campo senha.`);
            return false;
        }
        return true;
    };

    const gerarSenha = () => {
        var senhaForte = password_generateStrong(12);
        document.getElementById('Senha').value = senhaForte;
        setSenha(senhaForte);
        waitForElement('SenhaConfirmacao', el => {
            el.value = senhaForte;
            var els = document.querySelectorAll('#senhas input');
            Array.from(els).forEach(x => x.validate && x.validate());
        });
    };

    const salvar = () => {
        var res = FromFormReference(formRef.current);

        ValidateForm(formRef.current).then(ret => {
            window.showWait(true);
            if (!res.revendedorId) res.revendedorId = window.cnf.usuario.revendedorId;

            if (id != 0) {
                res.id = id;
                getAxios().put(API_URL + "/usuario", res).then(ret => {
                    Swal.fire("Sucesso!", "Cadastro atualizado com sucesso!", "success");
                    props.irPaginaPrincipal();
                    window.showWait(false);
                }).catch(ex => {
                    console.log(ex.response.data.errors);
                    Swal.fire("Ocorreu um erro!", ex.response.data, "error");
                    window.showWait(false);
                });
            } else {
                getAxios().post(API_URL + "/usuario", res).then(ret => {
                    if (ret) {
                        Swal.fire("Sucesso!", "Cadastro realizado com sucesso!", "success");
                    } else {
                        Swal.fire("Ocorreu um erro!", "Erro: Falha ao cadastrar usuário!", "error");
                    }
                    props.irPaginaPrincipal();
                    window.showWait(false);
                }).catch(ex => {
                    if (ex.response) {
                        if (ex.response.status === 404) {
                            Swal.fire("Ocorreu um erro!", "Não foi possível cadastrar usuário: " + ex.response.data, "error");
                        } else {
                            Swal.fire("Ocorreu um erro!", "Erro: " + ex.response.data, "error");
                        }
                    } else {
                        Swal.fire("Ocorreu um erro!", "Erro inesperado: " + ex.message, "error");
                    }
                    console.log(ex.response ? ex.response.data.errors : ex.message);
                    window.showWait(false);
                });
            }
        });
    };

    useEffect(() => {
        if (id == 0) {
            setSenha("");
            var required = true;
            setPropSenha({ required });
        } else {
            var required = false;
            setPropSenha({ required });
        }
    }, [id]);

    useEffect(() => {
        let vr = formRef.current;
        fire.register('editar_usuario', e => {
            carregarUsuario(e, vr);
        });

        fire.register('visualizar_usuario', e => {
            carregarUsuario(e, vr, true);
        });
    }, [formRef.current]);

    const carregarUsuario = (e, vr, isView = false) => {
        const form = vr;
        form?.reset();
        setId(0);

        if (e == undefined) return;

        window.showWait(true);
        getAxios().get(API_URL + "/usuario/" + e.id).then(res => {
            var data = res.data;
            preencheFormulario(data, form, isView);
            setId(data.id);
            window.showWait(false);
        }).catch(() => window.showWait(false));
    };

    const preencheFormulario = (data, form, isView = false) => {
        data = { ...data };
        toFormValues({ current: form }, data);
        setPerfilId(data.perfilId);
        var confUser = getConfig();
        if (isView) {
            var elementos = Array.from(formRef.current);
            elementos.forEach(element => {
                
                if (element.id !== "btnFechar" && !element.classList.contains('noLock') && element.id !== "SenhaConfirmacao" && element.id !== "Senha" && element.id !== "btnSalvar") {
                    element.setAttribute('disabled', 'disabled');
                } else if ((element.id === "SenhaConfirmacao" || element.id === "Senha" || element.id === "btnSalvar") && data.perfilId >= confUser.perfilId) {
                    element.remove();
                    element.closest('.row').remove();
                }
            });
        }
    };

    return <>
        <form ref={formRef}>
            <Row>
                <Col className='col-6 col-md-2'>
                    <MInput type="mask"
                        validate={e => e.string().required(`Campo Cpf obrigatório`).test('valida cpf', 'Cpf inválido', validarCpfCnpj)}
                        name="Cpf"
                        mask={"999.999.999-99"}
                        title="CPF" />
                </Col>
                <Col className="col-12 col-md-12">
                    <MInput id="Nome" type="text" title="Nome" required />
                </Col>
            </Row>
            <Row>
                <Col className="col-12 col-md-4">
                    <MInput id="Login" type="text" title="Login" required />
                </Col>
                {getPermissao(tipoPermissao.criar_usuario, tipoPerfil.Backoffice) &&
                    <Col className="col-md-8 col-12">
                        <MInput id="revendedorId" required type="selectSearch"
                            title="Empresa"
                            url={API_URL + "/revendedor/ListarRevendedores"}
                            params={v => ({ TermoDaBusca: v })}
                            onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                            placeholder="Digite o nome da empresa..." />
                    </Col>}
            </Row>
            <Row>
                <Col className='col-6 col-md-2'>
                    <MInput type="select" id="Sexo" default="-- Selecione --" required data={tipoSexo} title="Sexo" />
                </Col>
                <Col className='col-6 col-md-2'>
                    <MInput type="select" id="EstadoCivil" default="-- Selecione --" required data={tipoEstadoCivil} title="Estado Civil" />
                </Col>
                <Col className="col-md-3 col-6">
                    <MInput id="DocIdentidade" type="text" title="Nº Identidade" />
                </Col>
                <Col className="col-md-5 col-12">
                    <MInput id="NomeMae" required type="text" title="Nome da Mãe" />
                </Col>
            </Row>
            <hr />
            <strong>CONTATO</strong>
            <Row>
                <Col className="col-md-6 col-12">
                    <MInput id="EmailPessoal" type="email" title="Email Pessoal" />
                </Col>
                <Col className="col-md-2 col-6">
                    <MInput name="telefonePessoal" type="mask" mask="(99)99999-9999" title="Telefone Celular" onBlur={e => normalizaTelefone(e)} />
                </Col>
            </Row>
            <Row>
                <Col className="col-md-6 col-12">
                    <MInput id="EmailResponsavel" required type="email" title="Email Responsável" />
                </Col>
                <Col className="col-md-6 col-12">
                    <MInput id="EmailCorporativo" type="email" title="Email Corporativo" />
                </Col>
            </Row>
            {id !== 0 &&
            <><hr />
            <strong>SEGURANÇA</strong>
            <Row id="senhas">
                <a href="javascript:;" onClick={() => gerarSenha()}>Gerar senha automática!</a>
                <Col className="col-md-3 col-12">
                    <MInput id="Senha" {...propSenha} onChange={e => setSenha(e.target.value)} type="password" title="Senha" />
                </Col>
                {(id === 0 || senhaAtual.length > 0) &&
                    <Col className="col-md-3 col-12">
                        <MInput id="SenhaConfirmacao" validate={e => e.string().test('valida senha', 'Senha invalida!', validarSenha)} required type="password" title="Confirmação de Senha" />
                    </Col>}
                </Row></>
            }
            <hr />
            <Row>
                <Col className='col-6 col-md-3'>
                    <MInput
                        id="situacaoUsuarioId"
                        type="select"
                        data={props.situacaoUsuario}
                        title="Situação"
                        onResult={res => res.map(z => ({ value: z.id, text: z.nome }))} />
                </Col>
                <Col className='col-6 col-md-3'>
                    <MInput type="select" id="perfilId"
                        url={API_URL + "/usuario/ListarPerfisUsuario"}
                        defaultValue={propPerfilId} 
                        onResult={res => res.map(q => ({ value: q.id, text: q.nome }))}
                        onSelect={e => selecaoPerfil(e)}
                        title="Perfil" />
                </Col>
            </Row>
            <hr />
            <strong>Permissões</strong>
            <table className='table table-striped'>
                <tr>
                    <th>Permissão</th>
                    <th>Função</th>
                </tr>
                {permissoes?.map(x => (
                    <tr>
                        <td>{x.nome}</td>
                        <td>{x.observacao}</td>
                    </tr>
                ))}
            </table>
            <div style={{ position: "fixed", bottom: "5px", height: "60px", backgroundColor: "white", textAlign: "right", width: "100%" }}>
                <div style={{ width: "100%", paddingRight: "28%" }}>
                    <div style={{ float: "right" }}>
                        <Button id="btnSalvar" onClick={() => salvar()}>
                            <i className="fa fa-save"></i>
                            Salvar
                        </Button>
                        <Button onClick={e => cancelar()} id="btnFechar" className="btn-secondary">
                            Fechar
                        </Button>
                    </div>
                </div>
            </div>
            <div style={{ height: 400 }}>
            </div>
        </form>
    </>;
};
