import { React } from 'react';

export const MCard = (props) => {
    var childs = [];
    
    var mHeader = props.children.filter(x=> x.type?.name == "MCardHead");
    props.children.filter(x=> x.type?.name != "MCardHead").filter(x=> x != false ).forEach(x => childs.push(x));
 
    return (
        <><div className="card card-custom">
            {mHeader}
            <div className="card-body">
                {childs}
            </div>
        </div></>
    );
}

export const MCardHead = (props) => {
    return (
        <>
            <div className="card-header">
                <h3 className="card-title">{props.title}</h3>
                <div className="card-toolbar">
                    {props.children}
                </div>
            </div>
        </>
    );
}


export const MCardFooter = (props) => {
    return (
        <>
            <div className="card-footer">
                {props.children}
            </div>
        </>
    );
}
