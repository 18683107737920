import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'
import { API_URL } from '../../../components/MConfig'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/login/validar`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/login/esquecisenha`
 

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(usuario: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { usuario })
}

export function getUserByToken() {  
  return  axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
