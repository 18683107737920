import { useEffect, useState } from "react";
import { MCard, MCardHead } from "../../../components/MCard/MCard";
import { fire, getPermissao } from "../../../../util";
import { ListagemUsuario } from "./ListagemUsuario";
import { CadastroUsuario } from "./CadastroUsuario";
import { FiltroUsuario } from "./FiltroUsuario";
import { API_URL } from "../../../components/MConfig";
import { getAxios } from "../../../components/net";
import { tipoPermissao, tipoPerfil } from "../tipos/tipos";

export const Usuario = props => {
    const [edit, setEdit] = useState(false);
    const [toFire, setFire] = useState({});
    const [lista, setLista] = useState([]);
    const [situacaoUsuario, setSituacaoUsuario] = useState([]);

    const novo = () => {
        setEdit(true);
        setFire({ event: 'novo_usuario' });
    };

    const irPaginaPrincipal = () => {
        setEdit(false);
    };

    const editarCadastro = e => {
        e.isEdit = true;
        setEdit(e);
        setFire({ event: 'editar_usuario', e });
    };

    const visualizarCadastro = e => {
        e.isView = true;
        setEdit(e);
        setFire({ event: 'visualizar_usuario', e });
    };

    useEffect(() => {
        if (toFire.event == undefined) return;
        fire.call(toFire.event, toFire.e);
    }, [toFire]);

    useEffect(() => {
        getAxios().get(API_URL + "/usuario/ListarSituacoesUsuario").then(res => {
            if (res.data) {
                setSituacaoUsuario(res.data);
            }
        });

        fire.register('visualizar_usuario', e => {
            visualizarCadastro(e);
        });
    }, []);

    return <>
        {getPermissao(tipoPermissao.listar_usuario, tipoPerfil.Backoffice) &&
            <MCard>
                <MCardHead title={<i className="fa fa-user"> Cadastro de Usuarios</i>}></MCardHead>
                <div>
                    <div style={{ display: (!edit ? "block" : "none") }}>
                        <FiltroUsuario situacaoUsuario={situacaoUsuario} novo={novo} ocultarNovo={!getPermissao(tipoPermissao.criar_usuario, tipoPerfil.Backoffice)} />
                        <ListagemUsuario editarCadastro={editarCadastro} visualizarUsuario={visualizarCadastro} lista={lista} />
                    </div>
                    {edit &&
                        <CadastroUsuario situacaoUsuario={situacaoUsuario} irPaginaPrincipal={irPaginaPrincipal} edit={edit} />
                    }
                </div>
            </MCard>
        }
    </>;
};
