import axios from 'axios';
export const getAxios = ()=>{
    
    axios.defaults.headers = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',         
    };

    if(window.token != null && window.token != undefined) 
        axios.defaults.headers.Authorization = {token : `Bearer ${window.token}`};

    axios.interceptors.response.use(response=>{
        return response;
    },error=>{
        if(error?.response?.status === 401){
            window.location.href = '/logout';
        }
        throw error;
    })

    return axios;
}