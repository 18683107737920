import React, { useState, useRef } from 'react';
import { MInput } from "../../../components/MInput/MInput";
import { getAxios } from "../../../components/net";
import { password_isStrongPassword } from "../../../../util";
import { Button } from "react-bootstrap-v5";
import { API_URL } from "../../../components/MConfig";
import { MCard, MCardHead } from "../../../components/MCard/MCard";
import Swal from 'sweetalert2';
import { tipoPermissao } from '../tipos/tipos';

export const AlterarSenhaPage = () => {
    const r = useRef();
    const [senhaAtual, setSenhaAtual] = useState("");

    const handlePasswordChangeSuccess = () => {
        Swal.fire({
            title: 'Sucesso!',
            text: 'Senha alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = '/logout';
            }
        });
    };

    const AlterarSenha = () => {
        const dataPost = new FormData(r.current);

        if (dataPost.get('SenhaNova') !== dataPost.get('Confirmacao')) {
            Swal.fire("Erro!", "Senha nova difere da que esta sendo confirmada!", "error");
            return;
        }

        const URL = `${API_URL}/usuario/alterarsenha`;

        getAxios().put(URL, dataPost).then(res => {
            if (res.data) {
                handlePasswordChangeSuccess();
            } else {
                Swal.fire("Erro!", "Falha ao alterar a senha!", "error");
            }
        }).catch(res => {
            if (res.response) {
                Swal.fire("Erro!", res.response.data, "error");
            } else {
                Swal.fire("Erro!", "Erro ao alterar a senha!", "error");
            }
        });
    };

    const validarSenha = () => {
        const senha = document.getElementById('SenhaNova').value;
        const senhaConfirmacao = document.getElementById('Confirmacao').value;

        if (!senha) return false;

        if (senha !== senhaConfirmacao) {
            Swal.fire("Senha diferente!", "Senha difere da outra que esta sendo confirmada!");
            return false;
        }

        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        if (!strongPasswordRegex.test(senha)) {
            Swal.fire("Senha fraca!", `Senha fraca!<br>
            <br>Coloque: 
            <br>
            <ul>
                <li>Letra maiuscula</li>
                <li>Letra minuscula</li>
                <li>Caractere especial</li>
                <li>Numero(s)</li>
                <li>No minimo 6 digitos</li>
            </ul>`);
            return false;
        }
        return true;
    };

    const inputStyle = {
        maxWidth: '400px',
        margin: 'auto'
    };

    return (
        <>
            <form ref={r}>
                <MCard>
                    <MCardHead title={<i className="fa fa-key"> Alterar Senha</i>} />
                    <div style={inputStyle}>
                        <MInput
                            title="Senha atual"
                            name="SenhaAtual"
                            id="SenhaAtual"
                            type="password"
                            onChange={(e) => setSenhaAtual(e.target.value)}
                        />
                    </div>
                    <div style={inputStyle}>
                        <MInput title="Nova Senha" name="SenhaNova" id="SenhaNova" type="password" />
                    </div>
                    {senhaAtual.length > 0 && (
                        <div style={inputStyle}>
                            <MInput
                                title="Confirme a Senha"
                                name="Confirmacao"
                                id="Confirmacao"
                                type="password"
                                validate={e => e.string().test('valida senha', 'Senha incorreta', validarSenha)}
                                required
                            />
                            <Button className="mt-5" onClick={AlterarSenha}>Salvar</Button>
                            <br/>A senha deve conter:<br/>
                                    <ul>
                                        <li>Letra maiuscula</li>
                                        <li>Letra minuscula</li>
                                        <li>Caractere especial</li>
                                        <li>Numero(s)</li>
                                        <li>No minimo 6 digitos</li>
                                    </ul>
                        </div>
                    )}
                </MCard>
            </form>
        </>
    );
};
