import React from 'react'
import {useEffect} from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { MasterInit } from './MasterInit'
import { PageDataProvider } from './core'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,  
} from '../partials'
import { MAlert } from '../../app/components/MAlert/MAlert'
import { WaitModal } from '../../app/components/Wait/wait'
import { Mensagem } from '../../app/modules/portal/Partial/Mensagem'
import { WebsockStart } from '../../setup/websocket'

const MasterLayout: React.FC = ({ children }) => {
 
  return (
    <PageDataProvider>
      <Mensagem />
      <WebsockStart />
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <HeaderWrapper />
          <div className='post d-flex flex-column-fluid' id='kt_post'>           
            <Content>
            {(window as any).cnf?.degusta && (window as any).cnf.degusta.degustacao == 1 ? <>
              <div className="alert alert-warning" role="alert">
                Você está utilizando o sistema na modalidade de degustação! <br />
                Você possui ate {(window as any).cnf.degusta.qtddegustacao} consultas para degustar.
              </div>
            </> : null}
              {children}
            </Content>
            <MAlert></MAlert>
            <WaitModal />
          </div>
        </div>
      </div>



      {/* begin:: Drawers */}
      <ActivityDrawer />
      <ExploreMain />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />

    </PageDataProvider>
  )
}

export { MasterLayout }
