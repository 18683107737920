import { Button, Col, Row } from "react-bootstrap-v5";
import { MInput } from "../../../components/MInput/MInput";
import { useEffect, useState } from "react";
import { API_URL } from "../../../components/MConfig";
import { fire, getPermissao } from "../../../../util";
import { Filtro } from "../Partial/Filtro";
import { tipoPerfil, tipoPermissao } from "../tipos/tipos";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";

export const FiltroPropostas = (props) => {
    const { control, setValue } = useForm();
    const [statusOptions, setStatusOptions] = useState([]);
    const [produtoIdFiltro, setProdutoId] = useState("");
    const [statusOptionsKompMedicamentos, setStatusOptionsKompMedicamentos] = useState([]);
    const [statusOptionsAASPA, setStatusOptionsAASPA] = useState([]);

    // Fun��o para buscar os status por produtoId
    const buscarStatus = (produtoId) => {
        if (produtoId) {
            axios
                .get(`${API_URL}/proposta/ListarStatusPorProdutoId/${produtoId}`)
                .then((response) => {
                    const options = response.data.map((z) => ({
                        value: z.id,
                        text: z.nome,
                    }));
                    console.log("Status options carregados:", options);
                    if (produtoId === "6") {
                        setStatusOptionsKompMedicamentos(options); // Salva as op��es de KompMedicamentos
                    } else if (produtoId === "8") {
                        setStatusOptionsAASPA(options); // Salva as op��es de AASPA
                    }
                })
                .catch((error) => {
                    console.error("Erro ao buscar os status:", error);
                });
        } else {
            console.log("Nenhum produto selecionado.");
            setStatusOptions([]);
        }
    };

    // Atualiza o statusOptions conforme o produtoId � alterado
    const handleProdutoClick = (e) => {
        const selectedValue = e.target.value;
        setProdutoId(selectedValue);
        setValue("produtoId", selectedValue);

        if (selectedValue === "6") {
            setStatusOptions(statusOptionsKompMedicamentos); // Define as op��es de KompMedicamentos
        } else if (selectedValue === "8") {
            setStatusOptions(statusOptionsAASPA); // Define as op��es de AASPA
        } else {
            setStatusOptions([]); // Limpa as op��es se nenhum produto relevante for selecionado
        }
    };

    // useEffect para buscar os status para os produtos espec�ficos
    useEffect(() => {
        buscarStatus("6");
        buscarStatus("8");
    }, []);

    // Debug das op��es de status
    useEffect(() => {
        console.log("Status options atualizadas:", statusOptions);
    }, [statusOptions]);

    return (
        <>
            <Filtro novo={props.novo} ocultarNovo={false} chamada="filtrar_proposta" urlDownload="/proposta/DownloadRelatorio">
                <Row>
                    <Col className="col-md-6 col-12">
                        <MInput
                            id="termoDaBusca"
                            type="text"
                            title="Nome ou CPF"
                        />
                    </Col>
                    <Col className="col-md-3">
                        <MInput type="date" id="DataCadastroInicio" title="Periodo Inicio" />
                    </Col>
                    <Col className="col-md-3">
                        <MInput id="DataCadastroFim" type="date" title="Periodo" />
                    </Col>
                    <Col className="col-md-4">
                        <MInput
                            id="produtoId"
                            type="select"
                            title="Produto"
                            data={props.produtos}
                            default="Todos"
                            onResult={(res) => res.map((z) => ({ value: z.id, text: z.nome }))}
                            onClick={handleProdutoClick} // Usando onClick para capturar a sele��o do produto
                        />
                    </Col>

                    {/* Renderiza o campo status baseado no produto selecionado */}
                    {produtoIdFiltro && (
                        <Col className="col-md-4 col-12">
                            <MInput
                                id="statusId"
                                type="selectCheckbox"
                                title="Status"
                                data={statusOptions}
                                default="Todos"
                                //onResult={x => x.map(q => ({ value: q.id, text: q.nome }))}
                            />
                        </Col>
                    )}

                    {!getPermissao(null, tipoPerfil.Vendedor) && (
                        <>
                            <Col className="col-md-4 col-12">
                                <MInput
                                    id="revendedorId"
                                    type="selectSearch"
                                    title="Empresa"
                                    placeholder="Digite o nome da empresa..."
                                    url={API_URL + "/revendedor/ListarRevendedores"}
                                    params={(v) => ({ TermoDaBusca: v })}
                                    onResult={(res) => res?.resultado?.map((z) => ({ value: z.id, text: z.nome }))}
                                />
                            </Col>
                            <Col className="col-md-4 col-12">
                                <MInput
                                    id="usuarioId"
                                    type="selectSearch"
                                    title="Usuario"
                                    placeholder="Digite o nome do usuario..."
                                    url={API_URL + "/Usuario/ListarUsuarios"}
                                    params={(v) => ({ TermoDaBusca: v })}
                                    onResult={(res) => res?.resultado?.map((z) => ({ value: z.id, text: z.nome }))}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </Filtro>
        </>
    );
};
