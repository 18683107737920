import { React, useEffect, useState } from 'react';
import { Eye, EyeSlashFill,Key  } from 'react-bootstrap-icons';
import { Form ,InputGroup} from "react-bootstrap-v5";

export const MInputPassword = props => {
  const [showPass, setShowPass] = useState(false);
  
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text id="password-input">
          <Key />
        </InputGroup.Text>
        <Form.Control
          type={showPass ? "text":"password"}
          {...props}
        />
        <InputGroup.Text onClick={clickHandler}>          
          {showPass ? <Eye /> : <EyeSlashFill /> }
        </InputGroup.Text>
      </InputGroup>
    </>
  );
}