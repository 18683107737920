import { useEffect, useRef, useState } from "react"
import { Button, Col, Row } from "react-bootstrap-v5"
import { MModal } from "../../../components/MModal/MModal";
import { guid } from "../../../../util";
import { getAxios } from "../../../components/net";
import { API_URL } from "../../../components/MConfig";
import Swal from "sweetalert2";

export const AnexosProposta = props => {

    const modal = useRef();
    const modalSubstituir = useRef();
    const modalExcluir = useRef();
    const modalRecusar = useRef();
    const [tipoDocumentos, setTipoDocumentos] = useState([{ id: 1, nome: "Documento Frente" }, { id: 2, nome: "Documento Verso" }, { id: 3, nome: "Selfie" }]);
    const [selecaoAtual, setselecaoAtual] = useState([]);
    const [guidInterno, setGuid] = useState("");


    const visualizar = (e) => { 
        var an = props.anexos.filter(x => x.tipoDocumentoId == e.id);
                
        if (an.length > 0) {
            var anexo = an[0];
            if(anexo?.id > 0){
                props.visualizarAnexo(anexo.id,false);
                return;
            } 
        }
    }

    const cnf = JSON.parse(window.localStorage.getItem("cnf"));
    const temPermissao = cnf && (cnf.perfilId === 11 || cnf.perfilId === 12 || cnf.perfilId === 13);

    useEffect(() => {
        
        if (guidInterno == "") return;
       
        const nInput = document.createElement('input');
        nInput.setAttribute('id', guidInterno);
        nInput.setAttribute('type', 'file');
        nInput.onchange = selecaoFoto;
        nInput.setAttribute("accept", ".jpg,.jpeg,.png");
        nInput.setAttribute("style", "display:none");
        var hasLoad = false;
       
        document.getElementsByTagName('body')[0].appendChild(nInput);

        window.addEventListener('change', function (event) {
            if(hasLoad) return;
            if(event.target?.files == null || event.target?.files?.length ==0) return;
            var nomeArquivo = event.target?.files[0].name;
            var extensao = nomeArquivo.split('.').pop();
            if (["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"].filter(x => x == extensao).length == 0) {
                Swal.fire("Arquivo inválido!", "Somente é permitido arquivos de imagem. JPG, JPEG ou PNG.", "warning");
                return;
            }
            selecaoFoto(event);
            hasLoad = true;
        });

        nInput.click();

    }, [guidInterno]);

    const selecaoFoto = event => {
        const fileInput = event.target;
        if (fileInput?.id == guidInterno) {
            if (fileInput.files.length > 0) {
                const file = fileInput.files[0];
                const reader = new FileReader();

                reader.onload = function (event) {
                    const base64String = event.target.result.split(',')[1];
                    var a = props.anexos ? [...props.anexos] : [];
                    var old = a.filter(z => z.tipoDocumentoId == selecaoAtual);
                    a = a.filter(z => z.tipoDocumentoId != selecaoAtual);
                    a.push({ tipoDocumentoId: selecaoAtual, anexo: base64String, selecionado: true, old });
                    
                    if(props.setarAnexos)
                        props?.setarAnexos(a);

                    if (document.body.contains(fileInput)) {
                        document.body.removeChild(fileInput);
                    }
                };

                reader?.readAsDataURL(file);
            }
        }
    }


    const selecionaDocumento = selecao => {
        genInput();
        setselecaoAtual(selecao);
    }

    const genInput = () => {
        var g = guid("input");
        setGuid(g);
        return g;
    }

    const confirmarExclusao = item => {
        var e = item.modal.dataset;
        var an = props.anexos.filter(x => x.tipoDocumentoId == e.id);
        if (an.length > 0 && an[0].old.length > 0) {
            var newan = props.anexos.filter(x => x.tipoDocumentoId != e.id);
            newan.push(an[0].old[0]);
            props?.setarAnexos(newan);
        } else {
            props?.setarAnexos(props.anexos.filter(x => x.tipoDocumentoId != e.id));
        }
    }
    const substituirSelecao = e => {
        modalSubstituir.current.Show(e);
    }

    const realizaSubstituicao = e => {
        selecionaDocumento(modalSubstituir.current.dataset.id);
    }

    const situacao = e => {
        var an = (props.anexos ?? []).filter(x => x.tipoDocumentoId == e.id);

        var obj = { foto: false, enviado: false, selecionado: false, status: "Pendente", local: "", tipoDocumentoId: 0, propostaId: 0  }
        debugger;
        if (an.length) {
            console.log(an[0]);
            obj.foto = true;
            obj.local = an[0].local;
            obj.propostaId = an[0].propostaId;

            obj.tipoDocumentoId = e.id;
            if (an[0].selecionado) {
                obj.status = "Selecionado";
                obj.selecionado = true;
            }
            else {
                obj.status = "Enviado"
                obj.enviado = true;
            }
        }

        return obj;

    }

    const confirmarRecusa = item => {

        var e = item.modal.dataset;
        var an = props.anexos.filter(x => x.tipoDocumentoId == e.id);
        if (an.length > 0) {

            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(window.localStorage.getItem("cnf")).usuario.token}`,
                    userId: JSON.parse(window.localStorage.getItem("cnf")).usuario.id
                }
            };

            var newan = props.anexos.filter(x => x.tipoDocumentoId != e.id);
            //newan.push(an[0]);
            an[0].observacao = document.getElementById('observacao').value;
            an[0].anexosValidos = newan;
            an[0].usuarioId = JSON.parse(window.localStorage.getItem("cnf")).usuario.id;
            window.showWait(true);
            var URL = `${API_URL}/Anexo/RecusarAnexo`;

            getAxios().post(URL, an[0], config).then(res => {
                window.showWait(false);
                Swal.fire("Sucesso!", "Anexo Recusado com sucesso!", "success");
                props.recarregarProposta();
            }).catch(res => {
                console.log(res);
                window.showWait(false);
                Swal.fire("Erro!", "<ul>" + JSON.stringify(res.response.data).split("\\r\\n").map(x => "<li>" + x + "<li>").join('') + "</ul>");
            });

            props?.setarAnexos(newan);
        }
    }

    const excluirAnexo = e => {
        modalExcluir.current.Show(e);
    }

    const recusarAnexo = e => {
        modalRecusar.current.Show(e);
    }

    return <>

        <MModal size={1.5} ref={modal} title="Seleção de Anexo" closeText="Fechar" >
            <Col className="col-12">
                <input type="image" id="viewImage" style={{ width: "100%", height: "100%" }} />
            </Col>
        </MModal>

        <MModal
            ref={modalExcluir}
            onClickSave={confirmarExclusao} size={1.5}
            saveButton="Substituir" title="Excluir documento?" closeText="Fechar" saveText="Confirmar" >
            Deseja realmente excluir este anexo?
        </MModal>

        <MModal
            ref={modalRecusar}
            onClickSave={confirmarRecusa} size={1.5}
            saveButton="Substituir" title="Recusar documento?" closeText="Fechar" saveText="Confirmar">
            ATENÇÃO!
            <p>
                Deseja realmente recusar este Anexo?
            </p>
            <p>Esta ação será aplicada sem a necessidade de salvar a proposta. </p>
            <input type="textArea" id="observacao" style={{ width: "100%", height: "100%" }} />
        </MModal>

        <MModal
            onClickSave={realizaSubstituicao} size={1.5} ref={modalSubstituir}
            saveButton="Substituir" title="Susbtituir documento?" closeText="Fechar" saveText="Confirmar substituicao" >
            <Col className="col-12">
                ATENÇÃO!
                <p>
                    Você fará uma substituição de documento.
                    deseja realmente confirmar esta acao?
                </p>
                <p>Esta ação será aplicada após salvar a proposta. </p>
            </Col>
        </MModal>

        <Row>
            <div id="bloco_documentos">
                <strong>DOCUMENTOS</strong>
                <div style={{ width: "100%", overflow: "auto" }}>
                    <table className="table table-striped">
                        <tr>
                            <th>Status</th>
                            <th>Documento</th>
                            <th style={{width: "30px"}}>Visualizar</th>
                            <th style={{width: "30px"}}>Anexar/Reenviar</th>
                            <th style={{width: "30px"}}>Recusar</th>
                        </tr>
                        <tbody>
                            {tipoDocumentos.map(x => {
                                const situacaoAtual = situacao(x);

                                if ((situacaoAtual.status === "Pendente" && x.id === 3 && situacaoAtual.propostaId === 0 && document.getElementById("codigoProposta") === null) ||
                                    (document.getElementById("codigoProposta") !== null && document.getElementById("nomeStatus")?.innerText.indexOf("ACEITE") >= 0 && x.id === 3)) {
                                    
                                    return null;
                                }

                                return (
                                    <tr key={x.nome}>
                                        <td style={{ width: "20px" }}>
                                            {situacaoAtual.foto ?
                                                <i className="fa fa-check-circle fa-2xs" style={{ color: "green", fontSize: "1.3em" }} >{situacaoAtual.status}</i> :
                                                <i className="fa fa-hourglass-half" style={{ color: "red", fontSize: "2em" }} >Pendente</i>}
                                        </td>
                                        <td>{x.nome}</td>
                                        <td>
                                            {
                                                situacaoAtual.foto &&
                                                <Button className="btn-sm noLock" onClick={() => visualizar(x)}>
                                                    <i className='fa fa-eye' > </i>
                                                </Button>
                                            }
                                        </td>
                                        <td>
                                            {
                                                (!situacaoAtual.selecionado && !situacaoAtual.enviado) &&
                                                <Button title="Anexar documento" className="btn-sm" onClick={() => selecionaDocumento(x.id)}>
                                                    <i className='fa fa-paperclip' > </i>
                                                </Button>
                                            }
                                            {
                                                situacaoAtual.selecionado &&
                                                <Button title="Excluir anexo" className="btn-sm btn-danger" onClick={() => excluirAnexo(x)}>
                                                    <i className='fa fa-trash' > </i>
                                                </Button>
                                            }
                                            {
                                                (situacaoAtual.enviado && ((situacaoAtual.local !== "Dataqualify" && situacaoAtual.tipoDocumentoId === 3) || situacaoAtual.tipoDocumentoId !== 3)) &&
                                                <Button title="Reenviar anexo" className="btn-sm btn-info" onClick={() => substituirSelecao(x)}>
                                                    <i className='fa fa-eraser' > </i>
                                                </Button>
                                            }
                                        </td>
                                        <td>
                                            {
                                                situacaoAtual.enviado && temPermissao &&
                                                <Button title="Recusar anexo" className="btn-sm btn-dark" onClick={() => recusarAnexo(x)}>
                                                    <i className='fa fa-ban' > </i>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Row>
    </>
}