import { ValidateForm } from "../../../util";

export function limpa_formulário_cep() {

    var doc = document.getElementById('logradouro');
    if(doc) doc.value="";
    doc = document.getElementById('bairro')
    if(doc) doc.value="";
    doc = document.getElementById('cidade');
    if(doc) doc.value="";
    var doc = document.getElementById('uf');
    if(doc) doc.value="";        
}

    
export function pesquisacep(valor) {
    window.meu_callback = function(conteudo) {
        debugger;
    if (!("erro" in conteudo)) {
        //Atualiza os campos com os valores.
        var doc = document.getElementById('logradouro');
        if(doc) doc.value=(conteudo.logradouro);
        doc = document.getElementById('bairro')
        if(doc) doc.value=(conteudo.bairro);
        doc = document.getElementById('cidade');
        if(doc) doc.value=(conteudo.localidade);
        var doc = document.getElementById('uf');
        if (doc) {
            doc.reactSet(conteudo.uf);
        }
        var doc = document.getElementById('uf_text')
        if(doc) doc.value=(conteudo.uf);
        //if(doc?.reactSet)doc.reactSet(conteudo.uf);
    } //end if.
    else {
        //CEP não Encontrado.
        limpa_formulário_cep();
        alert("CEP não encontrado.");
    }
        var camposEndereco = Array.from(document.querySelectorAll('#bloco_endereco [name]')).filter(x=> x.id != "Endereco.EndNumero");
        ValidateForm(camposEndereco);
}


    //Nova variável "cep" somente com dígitos.
    var cep = valor.replace(/\D/g, '').padStart(8, '0');
    
    //Verifica se campo cep possui valor informado.
    if (cep != "") {

        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;

        //Valida o formato do CEP.
        if(validacep.test(cep)) {

            //Preenche os campos com "..." enquanto consulta webservice.
            document.getElementById('logradouro').value="...";
            document.getElementById('bairro').value="...";
            document.getElementById('cidade').value="...";
            document.getElementById('uf').value="...";            

            //Cria um elemento javascript.
            var script = document.createElement('script');

            //Sincroniza com o callback.
            script.src = 'https://viacep.com.br/ws/'+ cep + '/json/?callback=meu_callback';

            //Insere script no documento e carrega o conteúdo.
            document.body.appendChild(script);

        } //end if.
        else {
            //cep é inválido.
            limpa_formulário_cep();
            alert("Formato de CEP inválido.");
        }
    } //end if.
    else {
        //cep sem valor, limpa formulário.
        limpa_formulário_cep();
    }
};